import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { memo, useCallback } from "react";
import { BigButton, SettingsContainer, SettingsSubContainer } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import authSelectors from "../../../Store/Auth/selectors";
import appSelectors from "../../../Store/App/selectors";
import { appActions } from "../../../Store/App";
import UserForm from "./UserForm";
import SetAutoProlongSubscription from "../../PaymentForm/SetAutoProlongSubscription";
import DefaultSettingsForm from "./DefaultSettingsForm";
import ButtonsForm from "./ButtonsForm";

const Settings = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(appSelectors.isSettingsFormOpen);
  const loggedInUser = useSelector(authSelectors.loggedInUser);

  const onSettingsButtonClick = useCallback(
    () => dispatch(appActions.openSettingsForm()),
    [dispatch]
  );

  const onClose = useCallback(
    () => dispatch(appActions.closeSettingsForm()),
    [dispatch]
  );

  return (
    <>
      <Button
        size="large"
        variant="outlined"
        sx={{ height: "50px" }}
        onClick={onSettingsButtonClick}
      >
        <ManageAccountsIcon />
      </Button>
      <Modal open={isOpen} closeAfterTransition onClose={onClose}>
        <Fade in={isOpen}>
          <SettingsContainer sx={{ justifyContent: "space-between" }}>
            <>
              <SettingsSubContainer>
                <UserForm user={loggedInUser} />
                <ButtonsForm user={loggedInUser} />
              </SettingsSubContainer>
              <SettingsSubContainer>
                <DefaultSettingsForm />
                <BigButton variant="contained" onClick={onClose}>
                  ЗАКРЫТЬ
                </BigButton>
              </SettingsSubContainer>
            </>
          </SettingsContainer>
        </Fade>
      </Modal>
      <SetAutoProlongSubscription />
    </>
  );
};

export default memo(Settings);
