import superagent from "superagent";
import { BACKEND_URL } from "../constants";
import { isNil } from "lodash";

const setAutoProlongSubscription = async (
  state: boolean,
  accessToken: string
) => {
  const req = superagent.post(
    `${BACKEND_URL}/subscription/setAutoProlongSubscription/${state}`
  );

  if (!isNil(accessToken)) req.set("authorization", `Bearer ${accessToken}`);

  return req.then<number>((response) => response.statusCode);
};

const subscriptionApi = { setAutoProlongSubscription };

export default subscriptionApi;
