import Button from "@mui/material/Button";
import { CalculateInnerContainer } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { memo, useCallback } from "react";
import { uslugaActions } from "../../../Store/Usluga";
import CalcResults from "./Results";
import uslugaSelectors from "../../../Store/Usluga/selectors";
import authSelectors from "../../../Store/Auth/selectors";
import { appActions } from "../../../Store/App";
import LockIcon from "../../LockIcon";

const Calculate = () => {
  const dispatch = useDispatch();

  const selectedUsluga = useSelector(uslugaSelectors.getSelectedUsluga);
  const isSubscriptionValid = useSelector(authSelectors.isSubscriptionValid);

  const isSelectedUslugaLocked =
    !isSubscriptionValid && !selectedUsluga?.isFree;

  const onClick = useCallback(() => {
    if (isSelectedUslugaLocked) {
      dispatch(appActions.openSettingsForm());
      return;
    }

    dispatch(uslugaActions.calculate());
  }, [dispatch, isSelectedUslugaLocked]);

  return (
    <CalculateInnerContainer>
      <Button
        variant="outlined"
        size="large"
        sx={{ height: "64px" }}
        onClick={onClick}
      >
        РАССЧИТАТЬ&nbsp;&nbsp;
        {isSelectedUslugaLocked && <LockIcon />}
      </Button>
      <CalcResults />
    </CalculateInnerContainer>
  );
};

export default memo(Calculate);
