import { memo } from "react";
import { UslugaParamsContainer } from "./styled";
import { IUslugaParam } from "../../../../Store/Usluga/interfaces";
import UslugaParam from "./UslugaParam";

const UslugaParams = memo(
  ({ uslugaParams }: { uslugaParams: IUslugaParam[] }) => {
    return (
      <UslugaParamsContainer>
        {uslugaParams.map((uslugaParam) => (
          <UslugaParam uslugaParam={uslugaParam} key={uslugaParam.id} />
        ))}
      </UslugaParamsContainer>
    );
  }
);

export default UslugaParams;
