import { store } from ".";

export enum LoadingState {
  idle = "idle",
  loading = "loading",
  success = "success",
  fail = "fail",
}

export type ThenArg<T extends (...args: never) => Promise<unknown>> =
  ReturnType<T> extends Promise<infer U> ? U : T;

export type Store = typeof store;
export type AppState = ReturnType<Store["getState"]>;
