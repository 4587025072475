import { memo } from "react";
import { UslugaServicesContainer } from "./styled";
import { IUslugaService } from "../../../../Store/Usluga/interfaces";
import UslugaService from "./UslugaService";

const UslugaServices = memo(
  ({ uslugaServices }: { uslugaServices: IUslugaService[] }) => {
    return (
      <UslugaServicesContainer>
        {uslugaServices.map((uslugaService) => (
          <UslugaService uslugaService={uslugaService} key={uslugaService.id} />
        ))}
      </UslugaServicesContainer>
    );
  }
);

export default UslugaServices;
