import Tooltip from "@mui/material/Tooltip";
import MuiLockIcon from "@mui/icons-material/Lock";
import { memo } from "react";

const LockIcon = memo(() => (
  <Tooltip title="Авторизуйтесь и купите подписку">
    <MuiLockIcon fontSize="medium" color="disabled" />
  </Tooltip>
));

export default LockIcon;
