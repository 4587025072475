import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../interfaces";
import { CredentialsModalState, IAppState } from "./interfaces";
import { appReducers } from "./reducers";

const initialState: IAppState = {
  loadingState: LoadingState.loading,
  returnIndexDefault: null,
  innDogovorDefault: null,
  modals: {
    settings: false,
    credentials: CredentialsModalState.closed,
    payment: false,
    paymentComplete: false,
    setAutoProlongSubscription: false,
    aboutAuthor: false,
    aboutApp: false,
  },
  paymentForm: {
    products: [],
    productsLoadingState: LoadingState.idle,
    selectedProduct: null,
    buyingLoadingState: LoadingState.idle,
    psPaymentUrl: null,
    paymentCompleteState: false,
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: appReducers,
});

// Action creators are generated for each case reducer function
export const appActions = appSlice.actions;

export default appSlice.reducer;
