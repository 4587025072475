import { useSelector } from "react-redux";
import uslugaSelectors from "../../../../Store/Usluga/selectors";
import {
  CalculateResultsContainer,
  CalculateResultsDeliveryContainer,
  CalculateResultsErrorsContainer,
  CalculateResultsPriceContainer,
} from "./styled";
import Typography from "@mui/material/Typography";
import { isNil } from "lodash";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Popper from "@mui/material/Popper";
import { memo, useCallback, useState } from "react";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import Box from "@mui/material/Box";
import React from "react";

const CalcResults = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const isMobile =
    /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth < 760;

  const calcResults = useSelector(uslugaSelectors.calcResults);

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  }, []);

  const onClickAway = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      {isNil(calcResults.errors) && !isNil(calcResults.price) && (
        <CalculateResultsContainer>
          <CalculateResultsPriceContainer>
            <Typography fontSize={20} color={"#845ec2"}>
              {(calcResults.price?.withNds ?? 0) / 100} ₽ с НДС
            </Typography>
            <ClickAwayListener onClickAway={onClickAway}>
              <Box>
                <Popper
                  open={open}
                  anchorEl={anchorEl}
                  placement={isMobile ? "bottom-end" : "right-end"}
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        <Box sx={{ p: 2 }}>
                          {(calcResults.details ?? []).map((d) => (
                            <Box display="flex" key={d.name}>
                              <Typography>{d.name}:&nbsp;</Typography>
                              <Typography color={"#845ec2"}>
                                {d.price / 100} ₽ с НДС
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
                <IconButton onClick={onClick}>
                  <HelpOutlineIcon />
                </IconButton>
              </Box>
            </ClickAwayListener>
          </CalculateResultsPriceContainer>
          <CalculateResultsDeliveryContainer>
            <Typography fontSize={20} color={"#845ec2"}>
              От {calcResults.delivery?.min} до {calcResults.delivery?.max} дней
            </Typography>
          </CalculateResultsDeliveryContainer>
        </CalculateResultsContainer>
      )}
      {!isNil(calcResults.errors) && (
        <CalculateResultsErrorsContainer>
          {(calcResults.errors ?? []).map((errorMessage) => (
            <Typography color={"#e100a4"} marginTop="12px" key={errorMessage}>
              {errorMessage}
            </Typography>
          ))}
        </CalculateResultsErrorsContainer>
      )}
    </>
  );
};

export default memo(CalcResults);
