import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../Store/App/selectors";
import { appActions } from "../../Store/App";
import { BigLoadingButton, ModalContainer } from "./styled";
import { LoadingState } from "../../Store/interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import StarIcon from "@mui/icons-material/Star";
import { IProduct } from "../../Store/App/interfaces";
import { BigButton } from "../LeftPanel/Settings/styled";
import RecurrentPaymentsTerms from "./RecurrentPaymentsTerms";

const PaymentForm = memo(() => {
  const dispatch = useDispatch();

  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);

  useEffect(() => {
    dispatch(appActions.getProductsList());
  }, [dispatch]);

  const open = useSelector(appSelectors.isPaymentFormOpen);
  const products = useSelector(appSelectors.products);
  const productsLoadingState = useSelector(appSelectors.productsLoadingState);
  const buyingLoadingState = useSelector(appSelectors.buyingLoadingState);
  const selectedProduct = useSelector(appSelectors.selectedProduct);

  const onClose = useCallback(() => {
    dispatch(appActions.closePaymentForm());
  }, [dispatch]);

  const onClickProduct = useCallback(
    (product: IProduct) => () => {
      dispatch(appActions.setSelectedProduct(product));
    },
    [dispatch]
  );

  const onClickBuy = useCallback(() => {
    dispatch(appActions.buy());
  }, [dispatch]);

  return (
    <Modal open={open} closeAfterTransition onClose={onClose}>
      <Fade in={open}>
        <ModalContainer>
          {productsLoadingState === LoadingState.loading && (
            <CircularProgress />
          )}
          <List component="nav">
            {products.map((product) => (
              <ListItemButton
                selected={selectedProduct?.id === product.id}
                onClick={onClickProduct(product)}
                key={product.id}
              >
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText
                  primary={product.name}
                  secondary={`${product.price} руб.`}
                />
              </ListItemButton>
            ))}
          </List>
          <RecurrentPaymentsTerms
            checked1={agree1}
            setChecked1={setAgree1}
            checked2={agree2}
            setChecked2={setAgree2}
          />
          <BigLoadingButton
            loading={buyingLoadingState === LoadingState.loading}
            disabled={!agree1 || !agree2}
            variant="contained"
            color="success"
            onClick={onClickBuy}
          >
            КУПИТЬ
          </BigLoadingButton>
          <BigButton variant="contained" onClick={onClose}>
            ЗАКРЫТЬ
          </BigButton>
        </ModalContainer>
      </Fade>
    </Modal>
  );
});

export default PaymentForm;
