import { isEmpty, isNil } from "lodash";
import { IOps } from "../../Store/Map/interface";

export const convertOpsToYMapsObject = (ops: IOps) => {
  const index = ops.postalCode;
  const latitude = Number(ops.latitude);
  const longitude = Number(ops.longitude);
  const brandName = ops.brandName;
  const address = ops.address;
  const [logoUrl, iconUrl] = logoAndIcon(brandName);

  const balloonContentHeader = getBalloonContentHeader(
    logoUrl,
    index,
    brandName
  );
  const balloonContentBody = getBalloonContentBody(ops);
  const balloonContentFooter = getBalloonContentFooter(ops);
  const hintContent = getHintContent(logoUrl, index, brandName, address);

  return {
    type: "Feature",
    id: index,
    geometry: {
      type: "Point",
      coordinates: [latitude, longitude],
    },
    properties: {
      index,
      balloonContentHeader,
      balloonContentBody,
      balloonContentFooter,
      hintContent,
    },
    options: {
      hintPane: "hint",
      // iconLayout: "default#image",
      // iconImageHref: iconUrl,
      // iconImageSize: [32, 32],
      // iconImageOffset: [-16, -16],
    },
  };
};

const wds = ["", "пн", "вт", "ср", "чт", "пт", "сб", "вс"];

const getWorkTime = (ops: IOps) => {
  if (!isNil(ops.workTime) && !isEmpty(ops.workTime)) return [ops.workTime];

  return [...ops.workingHours]
    .sort((a, b) => a.weekday - b.weekday)
    .reduce<string[]>((acc, wh) => {
      const wd = wds[wh.weekday];
      const lunches = !isEmpty(wh.lunches)
        ? ",  перерыв: " +
          wh.lunches.map((l) => `${l.begin} - ${l.end}`).join(", ")
        : "";

      let period = "";
      let status = '<font color="#ff5e52">выходной</font>';

      if (wh.begin) {
        period = `${wh.begin} - ${wh.end}`;
        status = "открыто:";
      }

      acc.push(`${wd}, ${status} ${period}${lunches}`);

      return acc;
    }, []);
};

const logoAndIcon = (brandName: string) => {
  let logoUrl, iconUrl;

  switch (brandName.toUpperCase()) {
    case "ГЕРМЕС":
      logoUrl = "/images/hermes1.png";
      iconUrl = "/images/hermes_icon1.png";
      break;
    case "ХАЛВА":
      logoUrl = "/images/halva1.png";
      iconUrl = "/images/halva_icon1.png";
      break;
    case "ТЕЛЕПОРТ":
      logoUrl = "/images/teleport1.png";
      iconUrl = "/images/teleport_icon1.png";
      break;
    case "ОБУВЬ РОССИИ":
      logoUrl = "/images/obuvrus1.png";
      iconUrl = "/images/obuvrus_icon.png";
      break;
    default:
      logoUrl = "/images/pochta.png";
      iconUrl = "/images/rp_icon.png";
      break;
  }

  return [logoUrl, iconUrl];
};

const getBalloonContentHeader = (
  logoUrl: string,
  index: number,
  brandName: string
) => {
  return `<div id="logo" class="logo-balloon">
              <img src="${logoUrl}" style="max-height: 80px; max-width: 80px; height: auto; width: auto; vertical-align: middle;"/>
              <div>
                  <font size="4">
                      <b>&nbsp;${index}<br/>&nbsp;${brandName}</b>
                  </font>
              </div>
          </div>`;
};

const getBalloonContentBody = (ops: IOps): string => {
  const worktime = getWorkTime(ops);
  const { address, getto } = ops;

  let result = `<div style="margin:10px 0px 10px 0px;">
              <font size="3">${address}</font>
              <br />
              <font size="2">${getto ?? ""}</font>
          </div>`;

  if (!isNil(worktime) && !isEmpty(worktime))
    result += `<font size="3">
              ${worktime[0]}<br/>
              ${worktime[1]}<br/>
              ${worktime[2]}<br/>
              ${worktime[3]}<br/>
              ${worktime[4]}<br/>
              ${worktime[5]}<br/>
              ${worktime[6]}<br/>
          </font>`;

  if (ops.isTemporaryClosed)
    result += '<br/><font color="red" size="3">Временно закрыто</font>';

  return result;
};

const getBalloonContentFooter = (ops: IOps) => {
  const footer = `
          <div style="width: 100%; text-align: center;"> 
              <br />
              <button class="balloon-button" onclick="setDirection('from', '${ops.postalCode}', '${ops.address}'); return false;" style="--color: #2a53d3; --hover: #2a53d3; margin: 5px 15px 1px 0;line-height: 1.5;"> 
                  отсюда 
              </button> 
              <button class="balloon-button" onclick="setDirection('to', '${ops.postalCode}', '${ops.address}'); return false;" style="--color: #2a53d3; --hover: #2a53d3; margin: 5px 0 1px 15px; line-height: 1.5;"> 
                  &nbsp;&nbsp;сюда&nbsp;&nbsp; 
              </button>
          </div>`;

  return footer;
};

const getHintContent = (
  logoUrl: string,
  index: number,
  brandName: string,
  address: string
) => {
  return `<div id="logo" class="logo-balloon">
              <img src="${logoUrl}" style="max-height: 80px; max-width: 80px; height: auto; width: auto; vertical-align: middle;" />
              <div>
                  <font size="3">&nbsp;${index} ${brandName}<br/>&nbsp;${address}</font>
              </div>
          </div>`;
};
