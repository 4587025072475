import React, { memo } from "react";
import UslugaSelector from "./UslugaSelector";
import {
  AboutContainer,
  DirectionsContainer,
  LeftPanelInnerContainer,
} from "./styled";
import Logo from "./Logo";
import UslugaOptions from "./UslugaOptions";
import DirectionInput from "./DirectonInput";
import Calculate from "./Calculate";
import AboutAuthor from "./AboutAuthor/Index";
import AboutApp from "./AboutApp/Index";

export const LeftPanel = memo(() => (
  <LeftPanelInnerContainer>
    <Logo />
    <UslugaSelector />
    <UslugaOptions />
    <DirectionsContainer>
      <DirectionInput variant="from" />
      <DirectionInput variant="to" />
    </DirectionsContainer>
    <Calculate />
    <AboutContainer>
      <AboutAuthor />
      <AboutApp />
    </AboutContainer>
  </LeftPanelInnerContainer>
));
