import styled from "@emotion/styled";
import Autocomplete from "@mui/material/Autocomplete";
import { IUsluga } from "../../../Store/Usluga/interfaces";

export const StyledAutocomplete = styled(Autocomplete<IUsluga>)({
  width: "100%",
  marginTop: "8px",
  "fieldset.MuiOutlinedInput-notchedOutline": {
    border: "2px solid #845ec2",
  },
});
