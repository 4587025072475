import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { HTMLAttributes, memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uslugaActions } from "../../../Store/Usluga";
import uslugaSelectors from "../../../Store/Usluga/selectors";
import TextField from "@mui/material/TextField";
import { isNil } from "lodash";
import { IUsluga } from "../../../Store/Usluga/interfaces";
import { StyledAutocomplete } from "./styled";
import Box from "@mui/material/Box";
import { InputAdornment, Typography } from "@mui/material";
import authSelectors from "../../../Store/Auth/selectors";
import LockIcon from "../../LockIcon";

const UslugaSelector = memo(() => {
  const dispatch = useDispatch();

  const uslugas = useSelector(uslugaSelectors.getUslugasList);
  const selectedUsluga = useSelector(uslugaSelectors.getSelectedUsluga);
  const isSubscriptionValid = useSelector(authSelectors.isSubscriptionValid);

  const isSelectedUslugaLocked =
    !isSubscriptionValid && !selectedUsluga?.isFree;

  useEffect(() => {
    if (selectedUsluga?.category.id === 4) {
      alert(
        "Расчет цены для международных отправлений пока находится в разработке. Он станет доступен до конца года."
      );

      dispatch(uslugaActions.setSelectedUsluga(uslugas[0]));
    }
  }, [dispatch, selectedUsluga?.category.id, uslugas]);

  const getOptionLabel = useCallback((option: IUsluga) => option.name, []);

  const groupBy = useCallback((option: IUsluga) => option.category.name, []);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        label="Тип отправления"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {isSelectedUslugaLocked && (
                <InputAdornment position="end">
                  <LockIcon />
                </InputAdornment>
              )}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [isSelectedUslugaLocked]
  );

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: IUsluga) => {
      const isUslugaLocked = !isSubscriptionValid && !option.isFree;

      return (
        <Box
          {...props}
          component="li"
          key={option.name}
          display="flex"
          flexDirection="row"
          justifyContent="space-between !important"
        >
          <Typography color={isUslugaLocked ? "rgba(0, 0, 0, 0.26)" : "black"}>
            {option.name}
          </Typography>
          {isUslugaLocked && <LockIcon />}
        </Box>
      );
    },
    [isSubscriptionValid]
  );

  const onChange = useCallback(
    (e: any, value: IUsluga | null) => {
      if (isNil(value)) return;

      dispatch(uslugaActions.setSelectedUsluga(value));
    },
    [dispatch]
  );

  return (
    <StyledAutocomplete
      value={selectedUsluga}
      options={uslugas}
      size="small"
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput}
      renderOption={renderOption}
      onChange={onChange}
    />
  );
});

export default UslugaSelector;
