import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";

export const DirectionElementContainer = styled("div")({
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  "& .react-dadata__container": { width: "100%" },
});

export const IndexButton = styled(LoadingButton)({
  maxWidth: "fit-content",
});
