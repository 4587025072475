import { all, call, cancel, put, select, takeLatest } from "redux-saga/effects";
import { uslugaActions } from ".";
import { isNil } from "lodash";
import { ThenArg } from "../interfaces";
import uslugaSelectors from "./selectors";
import { ICalcTariffOptions, ICalcTariffRequest } from "./interfaces";
import appSelectors from "../App/selectors";
import authSelectors from "../Auth/selectors";
import rpUslugaApi from "../../API/Usluga";

function* fetchUslugaById(
  action: ReturnType<typeof uslugaActions.setSelectedUsluga>
) {
  try {
    if (isNil(action?.payload?.object)) {
      yield put(
        uslugaActions.setSelectedUslugaSuccess({ params: [], service: [] })
      );
      yield cancel();
    }

    const usluga: ThenArg<typeof rpUslugaApi.getById> = yield call(
      rpUslugaApi.getById,
      action.payload.object
    );

    const returnIndexDefault: ReturnType<
      typeof appSelectors.returnIndexDefault
    > = yield select(appSelectors.returnIndexDefault);
    const innDogovorDefault: ReturnType<typeof appSelectors.innDogovorDefault> =
      yield select(appSelectors.innDogovorDefault);

    yield put(
      uslugaActions.setSelectedUslugaSuccess({
        params: usluga.params,
        service: usluga.service,
        returnIndexDefault,
        innDogovorDefault,
      })
    );
  } catch (e) {
    console.log("fetchUslugaById", e);
    yield put(uslugaActions.setSelectedUslugaFail());
  }
}

function* calculate() {
  try {
    const calcOptions: ICalcTariffOptions = yield select(
      uslugaSelectors.calcOptions
    );

    const loggedInUser: ReturnType<typeof authSelectors.loggedInUser> =
      yield select(authSelectors.loggedInUser);

    const calcReqOptions: ICalcTariffRequest = {
      ...calcOptions,
      return: isNil(calcOptions.return) ? undefined : calcOptions.return,
      pack: isNil(calcOptions.pack) ? undefined : calcOptions.pack.id,
      group: isNil(calcOptions.group) ? undefined : calcOptions.group.id,
      transtype: isNil(calcOptions.transtype)
        ? undefined
        : calcOptions.transtype.id,
    };

    if (!isIndexValid(calcReqOptions.from)) {
      yield put(uslugaActions.calculateFail([invalidIndexErrorMsg("откуда")]));
      yield cancel();
    }

    if (!isIndexValid(calcReqOptions.to)) {
      yield put(uslugaActions.calculateFail([invalidIndexErrorMsg("куда")]));
      yield cancel();
    }

    const calculateResults: ThenArg<typeof rpUslugaApi.calculate> = yield call(
      rpUslugaApi.calculate,
      calcReqOptions,
      loggedInUser.accessToken ?? null
    );

    if (isNil(calculateResults.errors)) {
      yield put(uslugaActions.calculateSuccess(calculateResults));
    } else {
      yield put(uslugaActions.calculateFail(calculateResults.errors));
    }
  } catch (e) {
    console.log(e);
    yield put(uslugaActions.calculateFail(["Неизвестная ошибка"]));
  }
}

function* uslugasSaga() {
  yield all([
    takeLatest(uslugaActions.setSelectedUsluga, fetchUslugaById),
    takeLatest(uslugaActions.calculate, calculate),
  ]);
}

export default uslugasSaga;

const isIndexValid = (index: number | null): boolean => {
  if (isNil(index)) return false;
  if (isNaN(Number(index))) return false;
  if (String(index).length !== 6) return false;

  return true;
};

const invalidIndexErrorMsg = (type: string) =>
  `Индекс отделения ${type} не выбран. Уточните адрес в соответствующем поле выше.`;
