import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { memo, useCallback } from "react";
import { ModalContainer } from "../styled";
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../../Store/App/selectors";
import { appActions } from "../../../Store/App";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { BigButton } from "../../LeftPanel/Settings/styled";

const PaymentCompleteForm = memo(() => {
  const dispatch = useDispatch();

  const open = useSelector(appSelectors.isPaymentCompleteFormOpen);
  const paymentCompleteState = useSelector(appSelectors.paymentCompleteState);

  const onClose = useCallback(() => {
    dispatch(appActions.closePaymentCompleteForm());

    window.location.href = "/";
  }, [dispatch]);

  return (
    <Modal open={open} closeAfterTransition onClose={onClose}>
      <Fade in={open}>
        <ModalContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "600px",
            }}
          >
            {paymentCompleteState ? (
              <DoneIcon sx={{ fontSize: "6rem" }} color="success" />
            ) : (
              <CloseIcon sx={{ fontSize: "6rem" }} color="error" />
            )}
            {paymentCompleteState ? (
              <Typography variant="h6">Ваш заказ успешно оплачен</Typography>
            ) : (
              <Typography variant="h6">
                Не удалось оплатить заказ
                <br />
                Попробуйте еще раз
              </Typography>
            )}
            <br />
            <BigButton variant="contained" onClick={onClose}>
              ЗАКРЫТЬ
            </BigButton>
          </Box>
        </ModalContainer>
      </Fade>
    </Modal>
  );
});

export default PaymentCompleteForm;
