import { TextField } from "@mui/material";
import { useCallback, ChangeEvent, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../../../../Store/App";
import appSelectors from "../../../../../Store/App/selectors";

const InnDogovorDefault = () => {
  const dispatch = useDispatch();

  const innDogovorDefault = useSelector(appSelectors.innDogovorDefault);

  const onChangeInnDogovorDefault = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value === "" ? null : event.target.value;

      dispatch(appActions.updateInnDogovorDefault(newValue));
    },
    [dispatch]
  );

  return (
    <TextField
      label="ИНН-Договор по-умолчанию"
      value={innDogovorDefault ?? ""}
      fullWidth
      sx={{ marginTop: "12px" }}
      onChange={onChangeInnDogovorDefault}
    />
  );
};

export default memo(InnDogovorDefault);
