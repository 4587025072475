import { authInitialState } from ".";
import { LoadingState } from "../interfaces";
import { IAuthState } from "./interfaces";

const updateEmail = (state: IAuthState, action: { payload: string }) => {
  state.email = action.payload;
};

const updatePassword = (state: IAuthState, action: { payload: string }) => {
  state.password = action.payload;
};

const login = (state: IAuthState) => {
  state.loadingState = LoadingState.loading;
};

const loginWithGoogle = (state: IAuthState, action: { payload: string }) => {
  state.loadingState = LoadingState.loading;

  state.googleAccessToken = action.payload;
};

const loginSuccess = (
  state: IAuthState,
  action: {
    payload: {
      id: number;
      accessToken: string | null;
      email: string;
      emailValidatedAt: string | null;
      subscribedUntil: string | null;
      subscriptionAutoProlong: boolean;
    };
  }
) => {
  const payload = action.payload;

  state.user.id = payload.id;
  state.user.accessToken = payload.accessToken;
  state.user.email = payload.email;
  state.user.emailValidatedAt = payload.emailValidatedAt;
  state.user.subscribedUntil = payload.subscribedUntil;
  state.user.subscriptionAutoProlong = payload.subscriptionAutoProlong;

  state.loadingState = LoadingState.success;
  state.message = "";
};

const loginFail = (state: IAuthState) => {
  state.loadingState = LoadingState.fail;
  state.message = "Неверный e-mail или пароль";
};

const logout = (state: IAuthState) => {
  state.user = { ...authInitialState.user };
};

const register = (state: IAuthState) => {
  state.loadingState = LoadingState.loading;
};

const registerSuccess = (state: IAuthState) => {
  state.loadingState = LoadingState.success;
  state.message = "Успешно. Нажмите ВОЙТИ";
};

const registerFail = (state: IAuthState, action: { payload: string }) => {
  state.loadingState = LoadingState.fail;
  state.message = action.payload;
};

const setSubscriptionAutoProlong = (
  state: IAuthState,
  action: { payload: boolean }
) => {
  state.user.subscriptionAutoProlong = action.payload;
};

const validateEmail = (state: IAuthState) => {};

export const authReducers = {
  updateEmail,
  updatePassword,
  login,
  loginWithGoogle,
  loginSuccess,
  loginFail,
  logout,
  register,
  registerSuccess,
  registerFail,
  setSubscriptionAutoProlong,
  validateEmail,
};
