import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { appActions } from ".";
import { isNil, isEmpty } from "lodash";
import { uslugaActions } from "../Usluga";
import { IUsluga } from "../Usluga/interfaces";
import uslugaSelectors from "../Usluga/selectors";
import { ThenArg } from "../interfaces";
import { lsPrefix } from "..";
import { authActions } from "../Auth";
import appSelectors from "./selectors";
import subscriptionApi from "../../API/Subscription";
import authSelectors from "../Auth/selectors";
import authApi from "../../API/Auth";
import paymentApi from "../../API/Payment";
import rpUslugaApi from "../../API/Usluga";

function* appInit() {
  let lsData;

  try {
    lsData = JSON.parse(localStorage.getItem(`${lsPrefix}`) ?? "{}");

    if (!isNil(lsData?.authData)) {
      yield put(authActions.loginSuccess(lsData.authData));
    }

    if (!isNil(lsData?.settings)) {
      yield put(
        appActions.updateReturnIndexDefault(lsData.settings.returnIndexDefault)
      );
      yield put(
        appActions.updateInnDogovorDefault(lsData.settings.innDogovorDefault)
      );
    }
  } catch (error) {
    console.log("Auth data corrupted. Login failed.");
    yield put(authActions.logout());
  }

  const url = new URL(window.location.href);
  const paymentCompleteState = url.searchParams.get("payment_state");

  if (!isNil(paymentCompleteState)) {
    if (paymentCompleteState === "success")
      yield put(appActions.setPaymentCompleteState(true));

    if (paymentCompleteState === "fail")
      yield put(appActions.setPaymentCompleteState(false));

    yield put(appActions.openPaymentCompleteForm());
  }

  try {
    const userDb: ThenArg<typeof authApi.getUserById> = yield call(
      authApi.getUserById,
      lsData.authData.id,
      lsData.authData.accessToken
    );
    const userRedux: ReturnType<typeof authSelectors.loggedInUser> =
      yield select(authSelectors.loggedInUser);

    yield put(
      authActions.loginSuccess({
        accessToken: userRedux.accessToken ?? null,
        id: userRedux.id ?? 0,
        email: userRedux.email ?? "",
        emailValidatedAt: userRedux.emailValidatedAt ?? null,
        subscribedUntil: userDb.subscribedUntil ?? null,
        subscriptionAutoProlong: userDb.subscriptionAutoProlong,
      })
    );
  } catch (error) {
    console.log("Auth data corrupted. Login failed.");
    yield put(authActions.logout());
  }

  try {
    const uslugas: ThenArg<typeof rpUslugaApi.getList> = yield call(
      rpUslugaApi.getList
    );

    if (isNil(uslugas) || isEmpty(uslugas))
      yield put(uslugaActions.getUslugasListFail());

    yield put(uslugaActions.getUslugasListSuccess(uslugas));

    const sortedUslugas: IUsluga[] = yield select(
      uslugaSelectors.getUslugasList
    );

    const firstFreeUsluga = sortedUslugas.find((us) => us.isFree);

    yield put(uslugaActions.setSelectedUsluga(firstFreeUsluga!));
    yield put(appActions.initSuccess());
  } catch (e) {
    yield put(uslugaActions.getUslugasListFail());
  }
}

function* updateSettings() {
  try {
    const returnIndexDefault: ReturnType<
      typeof appSelectors.returnIndexDefault
    > = yield select(appSelectors.returnIndexDefault);
    const innDogovorDefault: ReturnType<typeof appSelectors.innDogovorDefault> =
      yield select(appSelectors.innDogovorDefault);

    const lsData = JSON.parse(localStorage.getItem(`${lsPrefix}`) ?? "{}");
    const lsSettings = lsData.settings ?? {};

    lsSettings.returnIndexDefault = returnIndexDefault;
    lsSettings.innDogovorDefault = innDogovorDefault;

    localStorage.setItem(
      `${lsPrefix}`,
      JSON.stringify({
        ...lsData,
        settings: { ...lsData.settings, returnIndexDefault, innDogovorDefault },
      })
    );
  } catch (error) {
    console.log("updateSettings Saga error", error);
  }
}

function* setAutoProlongSubscription() {
  try {
    const isAutoProlongSubscription: ReturnType<
      typeof authSelectors.isAutoProlongSubscription
    > = yield select(authSelectors.isAutoProlongSubscription);

    const user: ReturnType<typeof authSelectors.loggedInUser> = yield select(
      authSelectors.loggedInUser
    );

    const responseCode: number = yield call(
      subscriptionApi.setAutoProlongSubscription,
      !isAutoProlongSubscription,
      user.accessToken ?? ""
    );

    if (responseCode !== 200)
      throw new Error(`setAutoProlongSubscription failed: ${responseCode}`);

    yield put(appActions.setAutoProlongSubscriptionSuccess());
    yield put(
      authActions.setSubscriptionAutoProlong(!isAutoProlongSubscription)
    );
  } catch (error) {
    console.log("setAutoProlongSubscription Saga error", error);
    yield put(appActions.setAutoProlongSubscriptionFail());
  }
}

function* getProductsList() {
  try {
    const products: ThenArg<typeof paymentApi.getProductsList> = yield call(
      paymentApi.getProductsList
    );

    yield put(appActions.getProductsListSuccess(products));
    yield put(appActions.setSelectedProduct(products[0]));
  } catch (error) {
    console.log("getProductsList Failed", error);
    yield put(appActions.getProductsListFail());
  }
}

function* buy() {
  try {
    const selectedProduct: ReturnType<typeof appSelectors.selectedProduct> =
      yield select(appSelectors.selectedProduct);

    if (isNil(selectedProduct)) throw new Error("No selected product");

    const user: ReturnType<typeof authSelectors.loggedInUser> = yield select(
      authSelectors.loggedInUser
    );

    if (isNil(user)) throw new Error("No logged in user");

    const response: ThenArg<typeof paymentApi.buy> = yield call(
      paymentApi.buy,
      selectedProduct?.id ?? 0,
      user.accessToken ?? ""
    );

    yield put(appActions.buySuccess(response.paymentUrl));

    window.location.href = response.paymentUrl;
  } catch (error) {
    console.log("buySubscription Failed", error);
    yield put(appActions.buyFail());
  }
}

function* appSaga() {
  yield all([
    takeLeading(appActions.init, appInit),
    takeEvery(
      [appActions.updateReturnIndexDefault, appActions.updateInnDogovorDefault],
      updateSettings
    ),
    takeLatest(
      appActions.setAutoProlongSubscription,
      setAutoProlongSubscription
    ),
    takeLatest(appActions.getProductsList, getProductsList),
    takeLatest(appActions.buy, buy),
  ]);
}

export default appSaga;
