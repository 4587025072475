import { AppState } from "../interfaces";

const email = (state: AppState) => state.auth.email;
const password = (state: AppState) => state.auth.password;
const loadingState = (state: AppState) => state.auth.loadingState;
const message = (state: AppState) => state.auth.message;
const googleAccessToken = (state: AppState) => state.auth.googleAccessToken;
const loggedInUser = (state: AppState) => state.auth.user;
const isSubscriptionValid = (state: AppState) =>
  new Date(
    state.auth.user.subscribedUntil ?? new Date().getTime() - 1
  ).getTime() >= new Date().getTime();
const isAutoProlongSubscription = (state: AppState) =>
  state.auth.user.subscriptionAutoProlong;

const authSelectors = {
  email,
  password,
  loadingState,
  googleAccessToken,
  message,
  loggedInUser,
  isSubscriptionValid,
  isAutoProlongSubscription,
};

export default authSelectors;
