import { Typography } from "@mui/material";
import InnDogovorDefault from "./InnDogovorDefault";
import ReturnIndexDefault from "./ReturnIndexDefault";

const DefaultSettingsForm = () => {
  return (
    <>
      <Typography variant="h6">Настройки</Typography>
      <ReturnIndexDefault />
      <InnDogovorDefault />
    </>
  );
};

export default DefaultSettingsForm;
