import styled from "@emotion/styled";

export const UslugaParamsContainer = styled("div")(
  () =>
    `
width: 95%;
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-column-gap: 8px;
grid-row-gap: 8px;
margin-bottom: 24px;
`
);
