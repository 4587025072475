import { memo, useCallback, useEffect, useMemo } from "react";
import env from "react-dotenv";
import {
  YMaps as YMapProvider,
  Map as YMap,
  ObjectManager,
} from "@pbe/react-yandex-maps";
import { useDispatch, useSelector } from "react-redux";
import { mapActions } from "../../Store/Map";
import mapSelectors from "../../Store/Map/selectors";
import { convertOpsToYMapsObject } from "./helpers";
import { uslugaActions } from "../../Store/Usluga";
import { IDaDataMiniSuggestion } from "../../Store/Usluga/interfaces";

const controls = ["zoomControl", "fullscreenControl", "typeSelector"];
const modules = [
  // "control.ZoomControl",
  // "control.FullscreenControl",
  // "control.TypeSelector",
  // "objectManager.Balloon",
  // "objectManager.objects.balloon",
  // "geoObject.addon.balloon",
  // "geoObject.addon.hint",
  "package.full",
];
// const placemarkModules = [
//   "objectManager.Balloon",
//   "geoObject.addon.balloon",
//   "geoObject.addon.hint",
//   "objectManager.objects.balloon",
// ];

const Map = memo(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(
            mapActions.setMapCenter([
              position.coords.latitude,
              position.coords.longitude,
            ])
          );
        },
        (error_message) => {
          console.error(
            "An error has occured while retrieving location",
            error_message
          );
        }
      );
    }
  }, [dispatch]);

  useEffect(() => {
    // @ts-ignore window
    window.setDirection = (
      variant: "to" | "from",
      index: string,
      address: string
    ) => {
      dispatch(uslugaActions.updateCalcOptions({ [variant]: Number(index) }));

      const dadataObj: IDaDataMiniSuggestion = {
        value: address,
        unrestricted_value: address,
        data: { postal_code: String(index) },
      };

      if (variant === "from")
        dispatch(uslugaActions.setSuggestionFrom(dadataObj));
      if (variant === "to") dispatch(uslugaActions.setSuggestionTo(dadataObj));
    };
  }, [dispatch]);

  const zoom = useSelector(mapSelectors.zoom);
  const center = useSelector(mapSelectors.center);
  const selectedOps = useSelector(mapSelectors.selectedOps);

  const features = useMemo(
    () => selectedOps.map(convertOpsToYMapsObject),
    [selectedOps]
  );

  const onClickMap = useCallback(
    (event: UnknownYMapEvent) => {
      const coords = event.get("coords");
      dispatch(mapActions.setLastClickCoords(coords));
    },
    [dispatch]
  );

  return (
    <YMapProvider query={{ apikey: env.YMAPS_APIKEY }}>
      <div>
        <YMap
          state={{ center, zoom, controls }}
          width="100%"
          height="100vh"
          modules={modules}
          onClick={onClickMap}
        >
          <ObjectManager
            objects={{ preset: "islands#blueDotIcon" }}
            clusters={{ preset: "islands#blueClusterIcons" }}
            features={features}
            instanceRef={(om: any) => {
              // @ts-ignore window
              window.objManager = om;
            }}
          />
        </YMap>
      </div>
    </YMapProvider>
  );
});

export default Map;

type UnknownYMapEvent = any;
