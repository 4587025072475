import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../interfaces";
import { IAuthState } from "./interfaces";
import { authReducers } from "./reducers";

export const authInitialState: IAuthState = {
  email: "",
  password: "",
  loadingState: LoadingState.idle,
  message: "",
  googleAccessToken: null,
  user: {
    id: null,
    accessToken: null,
    subscribedUntil: null,
    emailValidatedAt: null,
    email: null,
    subscriptionAutoProlong: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: authReducers,
});

// Action creators are generated for each case reducer function
export const authActions = authSlice.actions;

export default authSlice.reducer;
