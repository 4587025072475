import { all, call, cancel, put, select, takeLatest } from "redux-saga/effects";
import { authActions } from ".";
import { ThenArg } from "../interfaces";
import authSelectors from "./selectors";
import authApi from "../../API/Auth";
import jwtDecode from "jwt-decode";
import { lsPrefix } from "..";
import { isArray, isNil } from "lodash";
import { appActions } from "../App";
import { CredentialsModalState } from "../App/interfaces";

function* login() {
  try {
    const email: ReturnType<typeof authSelectors.email> = yield select(
      authSelectors.email
    );
    const password: ReturnType<typeof authSelectors.password> = yield select(
      authSelectors.password
    );

    const params = { email, password };

    const response: ThenArg<typeof authApi.login> = yield call(
      // @ts-ignore
      authApi.login,
      params
    );

    const decodedToken: {
      id: number;
      sub: string;
      subscribedUntil: string;
      subscriptionAutoProlong: boolean;
      emailValidatedAt: string;
    } = jwtDecode(response.accessToken);

    const authData = {
      id: decodedToken.id,
      accessToken: response.accessToken,
      email: decodedToken.sub,
      emailValidatedAt: decodedToken.emailValidatedAt,
      subscribedUntil: decodedToken.subscribedUntil,
      subscriptionAutoProlong: decodedToken.subscriptionAutoProlong,
    };

    yield put(authActions.loginSuccess(authData));
    yield put(authActions.updateEmail(""));
    yield put(authActions.updatePassword(""));
    yield put(appActions.setCredentialsFormState(CredentialsModalState.closed));

    localStorage.setItem(`${lsPrefix}`, JSON.stringify({ authData }));
  } catch (e) {
    console.log("Login error", e);
    yield put(authActions.loginFail());
  }
}

function* loginWithGoogle() {
  try {
    const googleAccessToken: ReturnType<
      typeof authSelectors.googleAccessToken
    > = yield select(authSelectors.googleAccessToken);

    const response: ThenArg<typeof authApi.loginWithGoogle> = yield call(
      authApi.loginWithGoogle,
      googleAccessToken ?? ""
    );

    const decodedToken: {
      id: number;
      sub: string;
      subscribedUntil: string;
      subscriptionAutoProlong: boolean;
      emailValidatedAt: string;
    } = jwtDecode(response.accessToken);

    const authData = {
      id: decodedToken.id,
      accessToken: response.accessToken,
      email: decodedToken.sub,
      emailValidatedAt: decodedToken.emailValidatedAt,
      subscribedUntil: decodedToken.subscribedUntil,
      subscriptionAutoProlong: decodedToken.subscriptionAutoProlong,
    };

    yield put(authActions.loginSuccess(authData));
    yield put(appActions.setCredentialsFormState(CredentialsModalState.closed));

    localStorage.setItem(`${lsPrefix}`, JSON.stringify({ authData }));
  } catch (error) {
    console.log("Login error", error);
    yield put(authActions.loginFail());
  }
}

function* logout() {
  try {
    const lsData = JSON.parse(localStorage.getItem(`${lsPrefix}`) ?? "{}");

    if (!isNil(lsData?.authData)) {
      const { authData, ...rest } = lsData;
      localStorage.setItem(`${lsPrefix}`, JSON.stringify({ ...rest }));
    }

    yield null;
  } catch (error) {
    console.log("Logout failed", error);
  }
}

function* register() {
  let response: ThenArg<typeof authApi.register> = {
    email: "",
    subscribedUntil: null,
    message: "",
  };

  try {
    const email: ReturnType<typeof authSelectors.email> = yield select(
      authSelectors.email
    );
    const password: ReturnType<typeof authSelectors.password> = yield select(
      authSelectors.password
    );

    const params = { email, password };

    response = yield call(
      // @ts-ignore
      authApi.register,
      params
    );

    if (!isNil(response.message)) {
      const message = isArray(response.message)
        ? response.message[0]
        : response.message;
      yield put(authActions.registerFail(message));
      yield cancel();
    }

    yield put(authActions.registerSuccess());
    yield put(appActions.setCredentialsFormState(CredentialsModalState.closed));
  } catch (error) {
    const message = !isNil(response.message)
      ? isArray(response.message)
        ? response.message[0]
        : response.message
      : "";

    yield put(authActions.registerFail(message));
  }
}

function* validateEmail() {
  try {
    const loggedInUser: ReturnType<typeof authSelectors.loggedInUser> =
      yield select(authSelectors.loggedInUser);

    if (loggedInUser)
      yield call(
        authApi.validateEmail,
        loggedInUser.id ?? 0,
        loggedInUser.accessToken ?? ""
      );
  } catch (error) {
    console.log("Failed to validate email", error);
  }
}

function* authSaga() {
  yield all([
    takeLatest(authActions.login, login),
    takeLatest(authActions.loginWithGoogle, loginWithGoogle),
    takeLatest(authActions.logout, logout),
    takeLatest(authActions.register, register),
    takeLatest(authActions.validateEmail, validateEmail),
  ]);
}

export default authSaga;
