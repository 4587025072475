import Box from "@mui/material/Box";
import env from "react-dotenv";
import { memo, useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { isNil } from "lodash";
import CredentialsForm from "./CredentialsForm";
import { appActions } from "../../../../../Store/App";
import { CredentialsModalState } from "../../../../../Store/App/interfaces";
import { authActions } from "../../../../../Store/Auth";
import { BigButton } from "../../styled";

const LoginForm = memo(() => {
  const dispatch = useDispatch();

  const googleSignInRef = useRef(null);

  const onGoogleLogin = useCallback(
    (resp: { credential: string }) => {
      dispatch(authActions.loginWithGoogle(resp.credential));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isNil(googleSignInRef.current)) return;

    // @ts-ignore google login init
    google.accounts.id.initialize({
      client_id: env.GOOGLE_CLIENT_ID,
      callback: onGoogleLogin,
    });

    // @ts-ignore google login init
    google.accounts.id.renderButton(googleSignInRef.current, {
      theme: "outline",
      size: "large",
    });
  }, [dispatch, googleSignInRef, onGoogleLogin]);

  const onClick = useCallback(
    (state: CredentialsModalState) => () => {
      dispatch(appActions.setCredentialsFormState(state));
    },
    [dispatch]
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <BigButton
        variant="contained"
        onClick={onClick(CredentialsModalState.register)}
      >
        ЗАРЕГИСТРИРОВАТЬСЯ
      </BigButton>
      <BigButton
        variant="contained"
        onClick={onClick(CredentialsModalState.login)}
      >
        ВОЙТИ
      </BigButton>
      <Box mt="12px" mb="12px">
        ИЛИ
      </Box>
      <div id="google-sign-in" ref={googleSignInRef} />
      <CredentialsForm />
    </Box>
  );
});

export default LoginForm;
