import superagent from "superagent";
import { BACKEND_URL } from "../constants";
import { IAuthResponse, IRegisterResponse } from "./interfaces";
import { IUser } from "../../Store/Auth/interfaces";
import { isNil } from "lodash";

const login = async (params: { email: string; password: string }) =>
  superagent
    .post(`${BACKEND_URL}/auth`)
    .send({ email: params.email, password: params.password })
    .then<IAuthResponse>((response) => response.body);

const loginWithGoogle = async (googleAccessToken: string) =>
  superagent
    .post(`${BACKEND_URL}/auth/google/${googleAccessToken}`)
    .then<IAuthResponse>((response) => response.body);

const register = async (params: { email: string; password: string }) =>
  superagent
    .post(`${BACKEND_URL}/user`)
    .send({ email: params.email, password: params.password })
    .then<IRegisterResponse>((response) => response.body)
    .catch<IRegisterResponse>((response) => response.response.body);

const getUserById = async (id: number, accessToken: string) => {
  const req = superagent.get(`${BACKEND_URL}/user/${id}`);

  if (!isNil(accessToken)) req.set("authorization", `Bearer ${accessToken}`);

  return req
    .then<IUser>((response) => response.body)
    .catch<IUser>((response) => response.response.body);
};

const validateEmail = async (id: number, accessToken: string) => {
  const req = superagent.get(`${BACKEND_URL}/auth/validate-email/${id}`);

  if (!isNil(accessToken)) req.set("authorization", `Bearer ${accessToken}`);

  return req
    .then((response) => response.body)
    .catch((response) => response.response.body);
};

const authApi = {
  login,
  loginWithGoogle,
  register,
  getUserById,
  validateEmail,
};

export default authApi;
