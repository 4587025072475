import {
  DaDataSuggestion,
  ICalcTariffOptions,
  ICalcTariffResult,
  IUsluga,
  IUslugaSelectorState,
  UslugaParamType,
  UslugaParamTypeMap,
  UslugaReducer,
} from "./interfaces";
import { LoadingState } from "../interfaces";
import { isNil } from "lodash";
import dayjs from "dayjs";
import { uslugaSelectorInitialState } from ".";

const getUslugasList: UslugaReducer<void> = (state) => {
  state.loadingState = LoadingState.loading;
};

const getUslugasListSuccess: UslugaReducer<IUsluga[]> = (state, action) => {
  const uslugas = action?.payload ?? [];

  state.uslugas = uslugas;

  state.categories = uslugas.reduce<IUslugaSelectorState["categories"]>(
    (acc, usluga) => {
      if (acc.find((cat) => cat.id === usluga.categoryId)) return acc;

      acc.push(usluga.category);

      return acc;
    },
    []
  );

  state.loadingState = LoadingState.success;
};

const getUslugasListFail: UslugaReducer<void> = (state) => {
  state.loadingState = LoadingState.fail;
};

const setSelectedUsluga: UslugaReducer<IUsluga> = (state, action) => {
  state.selectedUsluga = action?.payload ?? null;
  state.selectedUslugaParams = action?.payload?.params ?? [];
  state.selectedUslugaServices = action?.payload?.service ?? [];

  state.calcOptions.object = action?.payload?.object;

  state.loadingState = LoadingState.loading;
};

const setSelectedUslugaSuccess: UslugaReducer<{
  params: IUsluga["params"];
  service: IUsluga["service"];
  returnIndexDefault?: number | null;
  innDogovorDefault?: string | null;
}> = (state, action) => {
  state.loadingState = LoadingState.success;

  state.selectedUslugaParams =
    action.payload?.params?.map((p) => ({
      ...p,
      // @ts-ignore Because API response and Redux store use the same interface but type of 'datatype' is different
      datatype: normDt(p.datatype),
    })) ?? [];
  state.selectedUslugaServices = action?.payload?.service ?? [];
  state.calcOptions = {
    ...uslugaSelectorInitialState.calcOptions,
    from: state.calcOptions.from,
    to: state.calcOptions.to,
    object: state.selectedUsluga?.object ?? null,
    return: action.payload?.params?.find(
      (p) => p.id === "return" && isNil(p.def)
    )
      ? action.payload?.returnIndexDefault ?? undefined
      : undefined,
    dogovor: action.payload?.params?.find(
      (p) => p.id === "dogovor" && isNil(p.def)
    )
      ? action.payload?.innDogovorDefault ?? undefined
      : undefined,
  };
  state.calcResults = { ...uslugaSelectorInitialState.calcResults };

  for (const p of state.selectedUslugaParams) {
    let normDefValue;

    // Arrays in Russian Post dont have a default value
    if (p.datatype === UslugaParamType.Array)
      // @ts-ignore
      state.calcOptions[p.param as keyof ICalcTariffOptions] = p.list?.[0];

    if (isNil(p.def)) continue;

    normDefValue = p.def;

    if (p.datatype === UslugaParamType.Text)
      normDefValue = String(normDefValue);

    if (
      p.datatype === UslugaParamType.Number ||
      p.datatype === UslugaParamType.Weight ||
      p.datatype === UslugaParamType.Sum
    )
      normDefValue = Number(normDefValue);

    if (p.datatype === UslugaParamType.Boolean)
      normDefValue = Boolean(normDefValue);

    if (p.datatype === UslugaParamType.Date)
      normDefValue = dayjs(normDefValue as string);

    // @ts-ignore
    state.calcOptions[p.param as keyof ICalcTariffOptions] = normDefValue;
  }
};

const setSelectedUslugaFail: UslugaReducer<void> = (state) => {
  state.loadingState = LoadingState.fail;
};

const updateCalcOptions: UslugaReducer<Partial<ICalcTariffOptions>> = (
  state,
  action
) => {
  if (!isNil(action.payload.to)) state.opsLoading.toState = LoadingState.idle;

  if (!isNil(action.payload.from))
    state.opsLoading.fromState = LoadingState.idle;

  state.calcOptions = {
    ...state.calcOptions,
    ...action.payload,
  };
};

const updateCalcOptionsServices: UslugaReducer<{
  service: { id: number; isEnabled: boolean };
  soff: number[];
}> = (state, action) => {
  const {
    payload: { service, soff },
  } = action;

  const newList = service.isEnabled
    ? [...state.calcOptions.service, service.id]
    : state.calcOptions.service.filter((s) => s !== service.id);

  state.calcOptions.service = newList.filter((s) => !soff.includes(s));
};

const setSuggestionFrom: UslugaReducer<DaDataSuggestion> = (state, action) => {
  state.fromSuggestion = action.payload;
};

const setSuggestionTo: UslugaReducer<DaDataSuggestion> = (state, action) => {
  state.toSuggestion = action.payload;
};

const calculate: UslugaReducer<void> = (state) => {
  state.loadingState = LoadingState.loading;
};

const calculateSuccess: UslugaReducer<ICalcTariffResult> = (state, action) => {
  state.calcResults = action.payload;

  state.loadingState = LoadingState.success;
};

const calculateFail: UslugaReducer<string[]> = (state, action) => {
  state.loadingState = LoadingState.fail;

  state.calcResults.errors = action.payload;
};

const loadOpsTo: UslugaReducer<void> = (state) => {
  state.opsLoading.toState = LoadingState.loading;
};

const loadOpsToSuccess: UslugaReducer<void> = (state) => {
  state.opsLoading.toState = LoadingState.success;
};

const loadOpsToFail: UslugaReducer<void> = (state) => {
  state.opsLoading.toState = LoadingState.fail;
};

const loadOpsFrom: UslugaReducer<void> = (state) => {
  state.opsLoading.fromState = LoadingState.loading;
};

const loadOpsFromSuccess: UslugaReducer<void> = (state) => {
  state.opsLoading.fromState = LoadingState.success;
};

const loadOpsFromFail: UslugaReducer<void> = (state) => {
  state.opsLoading.fromState = LoadingState.fail;
};

const uslugaReducers = {
  getUslugasList,
  getUslugasListSuccess,
  getUslugasListFail,

  setSelectedUsluga,
  setSelectedUslugaSuccess,
  setSelectedUslugaFail,

  updateCalcOptions,
  updateCalcOptionsServices,
  setSuggestionFrom,
  setSuggestionTo,
  calculate,
  calculateSuccess,
  calculateFail,

  loadOpsTo,
  loadOpsToSuccess,
  loadOpsToFail,
  loadOpsFrom,
  loadOpsFromSuccess,
  loadOpsFromFail,
};

export default uslugaReducers;

export const normDt = (
  rpDatatype: keyof typeof UslugaParamTypeMap
): UslugaParamType => UslugaParamTypeMap[rpDatatype];
