import { Box, Fade, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { ChangeEvent, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { appActions } from "../../../../../../Store/App";
import { CredentialsModalState } from "../../../../../../Store/App/interfaces";
import appSelectors from "../../../../../../Store/App/selectors";
import { authActions } from "../../../../../../Store/Auth";
import authSelectors from "../../../../../../Store/Auth/selectors";
import { LoadingState } from "../../../../../../Store/interfaces";
import { SettingsContainer, BigButton } from "../../../styled";

const CredentialsForm = memo(() => {
  const dispatch = useDispatch();

  const email = useSelector(authSelectors.email);
  const password = useSelector(authSelectors.password);
  const message = useSelector(authSelectors.message);
  const loadingState = useSelector(authSelectors.loadingState);

  const openState = useSelector(appSelectors.credentialsFormState);
  const isOpen = openState !== CredentialsModalState.closed;

  const onChangeEmail = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(authActions.updateEmail(ev.target.value));
    },
    [dispatch]
  );

  const onChangePassword = useCallback(
    (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(authActions.updatePassword(ev.target.value));
    },
    [dispatch]
  );

  const onClickClose = useCallback(() => {
    dispatch(appActions.setCredentialsFormState(CredentialsModalState.closed));
  }, [dispatch]);

  const onClick = useCallback(() => {
    if (openState === CredentialsModalState.register)
      dispatch(authActions.register());

    if (openState === CredentialsModalState.login)
      dispatch(authActions.login());
  }, [dispatch, openState]);

  return (
    <Modal open={isOpen} closeAfterTransition onClose={onClickClose}>
      <Fade in={isOpen}>
        <SettingsContainer>
          <TextField
            label="E-mail"
            fullWidth
            value={email}
            onChange={onChangeEmail}
          />
          <Box padding="4px" />
          <TextField
            label="Пароль"
            fullWidth
            type="password"
            value={password}
            onChange={onChangePassword}
          />
          <Box padding="16px">
            <Typography color={colorByState(loadingState)}>
              {message}
              &nbsp;
            </Typography>
          </Box>
          <LoadingButton
            loading={loadingState === LoadingState.loading}
            variant="contained"
            sx={{ height: "48px", width: "100%" }}
            onClick={onClick}
          >
            ОК
          </LoadingButton>
          <BigButton variant="contained" onClick={onClickClose}>
            ЗАКРЫТЬ
          </BigButton>
        </SettingsContainer>
      </Fade>
    </Modal>
  );
});

export default CredentialsForm;

const colorByState = (state: LoadingState) => {
  if (state === LoadingState.success) return "green";
  if (state === LoadingState.fail) return "red";

  return "black";
};
