import styled from "@emotion/styled";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";

export const SettingsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  minHeight: 600,
  backgroundColor: "white",
  boxShadow: "0px 0px 20px 0px black",
  padding: "12px",
  borderRadius: "4px",
}));

export const SettingsSubContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const BigButton = styled(Button)(() => ({
  height: "48px",
  width: "100%",
  marginTop: "12px",
}));
