import styled from "@emotion/styled";
import LoadingButton from "@mui/lab/LoadingButton";

export const ModalContainer = styled("div")(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  minHeight: 600,
  backgroundColor: "white",
  boxShadow: "0px 0px 20px 0px black",
  padding: "12px",
  borderRadius: "4px",
}));

export const BigLoadingButton = styled(LoadingButton)(() => ({
  height: "48px",
  width: "100%",
  marginTop: "8px",
}));
