import CircularProgress from "@mui/material/CircularProgress";
import { memo } from "react";
import { useSelector } from "react-redux";
import { LogoBarContainer } from "./styled";
import Typography from "@mui/material/Typography";
import Settings from "../Settings";
import appSelectors from "../../../Store/App/selectors";
import { Box } from "@mui/material";

const Logo = memo(() => {
  const isLoading = useSelector(appSelectors.isLoading);

  return (
    <LogoBarContainer>
      <Box sx={{ display: "inline-block" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/logo_cp.png`}
            alt="logo"
            width="50"
            height="50"
          />
          <Typography
            variant="h4"
            fontWeight={700}
            sx={{ marginLeft: "10px", color: "#1976d2" }}
          >
            Цена Посылки
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "inline-block" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            minWidth: "90px",
            justifyContent: "flex-end",
          }}
        >
          {isLoading && <CircularProgress />}
          &nbsp;
          <Settings />
        </Box>
      </Box>
    </LogoBarContainer>
  );
});

export default Logo;
