import { PayloadAction } from "@reduxjs/toolkit";
import { LoadingState } from "../interfaces";
import { DaDataAddressSuggestion } from "react-dadata";

export interface IUslugaSelectorState {
  selectedCompany: DeliveryCompany;
  selectedUsluga: IUsluga | null;
  selectedUslugaParams: IUslugaParam[];
  selectedUslugaServices: IUslugaService[];
  uslugas: IUsluga[];
  categories: IUsluga["category"][];
  loadingState: LoadingState;
  fromSuggestion: DaDataSuggestion;
  toSuggestion: DaDataSuggestion;
  opsLoading: {
    fromState: LoadingState;
    toState: LoadingState;
  };
  calcOptions: ICalcTariffOptions;
  calcResults: ICalcTariffResult;
}

export interface IDaDataMiniSuggestion {
  data: { postal_code: string };
  unrestricted_value: string;
  value: string;
}

export type DaDataSuggestion = DaDataAddressSuggestion | IDaDataMiniSuggestion;

export interface IUsluga {
  id: number;
  categoryId: number;
  name: string;
  description: string;
  object: number;
  category: ICategory;
  isFree: boolean;
  params?: IUslugaParam[];
  service?: IUslugaService[];
}

export interface IUslugaParam {
  id: string;
  datatype: UslugaParamType;
  name: string;
  param: string;
  seq: number;
  min?: number;
  max?: number;
  list?: IUslugaParamListItem[];
  def?: any;
  unit?: string[];
}

export interface IUslugaParamListItem {
  id: number;
  name: string;
  seq: number;
  serviceoff?: number[];
  soff?: number[];
}

export interface IUslugaService {
  id: number;
  name: string;
  seq: number;
  serviceoff?: number[];
  soff?: number[];
}

export interface ICategory {
  id: number;
  name: string;
}

export enum DeliveryCompany {
  RP = "RP",
  CDEC = "CDEC",
}

export type UslugaReducer<T> = (
  state: IUslugaSelectorState,
  action: PayloadAction<T>
) => void;

export enum UslugaParamType {
  Number = "Number",
  Text = "Text",
  Date = "Date",
  // Time = "Time",
  Boolean = "Boolean",
  Sum = "Sum",
  Weight = "Weight",
  Array = "Array",
}

export const UslugaParamTypeMap = {
  1: UslugaParamType.Number,
  2: UslugaParamType.Text,
  4: UslugaParamType.Date,
  5: UslugaParamType.Boolean,
  // 7: UslugaParamType.Time,
  13: UslugaParamType.Sum,
  29: UslugaParamType.Weight,
  33: UslugaParamType.Number,
  41: UslugaParamType.Array,
};

export interface ICalcTariffOptions {
  object: number | null;
  from: number | null;
  to: number | null;
  weight: number;
  service: number[];
  return?: number;
  pack?: { id: number; name: string };
  dogovor?: string;
  sumOc?: number;
  /** Сумма наложенного платежа... заметил только в EMS */
  sumNp?: number;
  date: string | null;
  time: string | null;
  calcIfForbidden?: boolean;
  /** Объект тарификации в посылках для юриков */
  group?: { id: number; name: string };
  /** Способ пересылки в ЕКОМ Маркетплейс */
  transtype?: { id: number; name: string };
}

export interface ICalcTariffRequest {
  object: number | null;
  from: number | null;
  to: number | null;
  weight: number;
  service: number[];
  return?: number;
  pack?: number;
  dogovor?: string;
  sumOc?: number;
  /** Сумма наложенного платежа... заметил только в EMS */
  sumNp?: number;
  date: string | null;
  time: string | null;
  calcIfForbidden?: boolean;
  /** Объект тарификации в посылках для юриков */
  group?: number;
  /** Способ пересылки в ЕКОМ Маркетплейс */
  transtype?: number;
}

export interface ICalcTariffResult {
  price: { withNds: number; withoutNds: number } | null;
  nds: { amount: number; rate: number } | null;
  delivery: { min: number; max: number } | null;
  details: { name: string; price: number }[] | null;
  errors: string[] | null;
}
