import { LoadingState } from "../interfaces";

export interface IAppState {
  loadingState: LoadingState;
  returnIndexDefault: number | null;
  innDogovorDefault: string | null;
  modals: {
    settings: boolean;
    credentials: CredentialsModalState;
    payment: boolean;
    paymentComplete: boolean;
    setAutoProlongSubscription: boolean;
    aboutAuthor: boolean;
    aboutApp: boolean;
  };
  paymentForm: {
    products: IProduct[];
    productsLoadingState: LoadingState;
    selectedProduct: IProduct | null;
    buyingLoadingState: LoadingState;
    psPaymentUrl: string | null;
    paymentCompleteState: boolean;
  };
}

export interface IProduct {
  id: number;
  name: string;
  description: string;
  price: number;
  monthsQty: number;
  createdAt: Date;
  updatedAt: Date;
}

export enum CredentialsModalState {
  closed = "closed",
  register = "register",
  login = "login",
}
