import superagent from "superagent";
import { BACKEND_URL } from "../constants";
import { IOps } from "../../Store/Map/interface";
import { isNil } from "lodash";

const getByIndex = async (index: number) =>
  superagent
    .get(`${BACKEND_URL}/russian-post/ops/index/${index}`)
    .then<IOps>((response) => response.body);

const getByCoords = async (lat: number, lon: number) =>
  superagent
    .get(`${BACKEND_URL}/russian-post/ops/coords/${lat}/${lon}`)
    .then<IOps[]>((response) => response.body);

const getByAddress = async (address: string) =>
  superagent
    .get(`${BACKEND_URL}/russian-post/ops/address/${address}`)
    .then<IOps>((response) => response.body);

const getByLocation = async (params: {
  settlement?: string;
  district?: string;
  region?: string;
}) => {
  const query = Object.entries(params)
    .filter(([k, v]) => !isNil(v))
    .map(([k, v]) => `${k}=${v}`)
    .join("&");

  return superagent
    .get(`${BACKEND_URL}/russian-post/ops/location?${query}`)
    .then<IOps>((response) => response.body);
};

const opsApi = { getByIndex, getByCoords, getByAddress, getByLocation };

export default opsApi;
