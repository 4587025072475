import { isNil } from "lodash";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../../../Store/App";
import authSelectors from "../../../../Store/Auth/selectors";
import { BigButton } from "../styled";
import { IUser } from "../../../../Store/Auth/interfaces";
import LoginForm from "./LoginForm";
import LogoutForm from "./LogoutForm";

const ButtonsForm = ({ user }: { user: IUser }) => {
  const dispatch = useDispatch();

  const isAutoProlongSubscription = useSelector(
    authSelectors.isAutoProlongSubscription
  );

  const onBuySubscriptionClick = useCallback(() => {
    dispatch(appActions.openPaymentForm());
  }, [dispatch]);

  const onSetAutoProlongSubscriptionClick = useCallback(() => {
    dispatch(appActions.openSetAutoProlongSubscriptionForm());
  }, [dispatch]);

  return !isNil(user.accessToken) ? (
    <>
      <BigButton
        variant="contained"
        color="success"
        onClick={onBuySubscriptionClick}
      >
        КУПИТЬ ПОДПИСКУ
      </BigButton>
      {!isNil(user.subscribedUntil) && (
        <BigButton
          variant="contained"
          onClick={onSetAutoProlongSubscriptionClick}
        >
          {isAutoProlongSubscription ? "ОТМЕНИТЬ" : "АКТИВИРОВАТЬ"} ПОДПИСКУ
        </BigButton>
      )}
      <LogoutForm />
    </>
  ) : (
    <LoginForm />
  );
};

export default memo(ButtonsForm);
