import { memo, useCallback } from "react";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ru";
import {
  ICalcTariffOptions,
  IUslugaParam,
  UslugaParamType,
} from "../../../../../Store/Usluga/interfaces";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { isNil } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { uslugaActions } from "../../../../../Store/Usluga";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import uslugaSelectors from "../../../../../Store/Usluga/selectors";
import Typography from "@mui/material/Typography";

const UslugaParam = memo(({ uslugaParam }: { uslugaParam: IUslugaParam }) => {
  const dispatch = useDispatch();

  const value: any = useSelector(uslugaSelectors.calcOptions)[
    uslugaParam.param as keyof ICalcTariffOptions
  ];

  const onChange = useCallback(
    (param: string) => (event: any) => {
      dispatch(
        uslugaActions.updateCalcOptions({ [param]: event.target.value })
      );
    },
    [dispatch]
  );

  const onChangeNumber = useCallback(
    (param: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue =
        event.target.value === "" ? null : Number(event.target.value);

      if (Number.isNaN(newValue)) return;

      dispatch(uslugaActions.updateCalcOptions({ [param]: newValue }));
    },
    [dispatch]
  );

  const onChangeBoolean = useCallback(
    (param: string) => (event: any) => {
      dispatch(
        uslugaActions.updateCalcOptions({ [param]: event.target.checked })
      );
    },
    [dispatch]
  );

  const onChangeDate = useCallback(
    (param: string) => (event: Dayjs | null) => {
      if (isNil(event)) return;

      dispatch(
        uslugaActions.updateCalcOptions({ [param]: event.format("YYYYMMDD") })
      );
    },
    [dispatch]
  );

  const onChangeAC = useCallback(
    (param: string) => (_: any, value: any) => {
      dispatch(uslugaActions.updateCalcOptions({ [param]: value }));
    },
    [dispatch]
  );

  const getOptionLabel = useCallback(
    (option: { name: string }) => option.name ?? "",
    []
  );
  const renderInput = useCallback(
    (label: string) => (params: AutocompleteRenderInputParams) =>
      <TextField {...params} label={label} />,
    []
  );

  return (
    <>
      {uslugaParam.datatype === UslugaParamType.Text && (
        <TextField
          label={uslugaParam.name}
          variant="standard"
          size="small"
          value={value}
          onChange={onChange(uslugaParam.param)}
        />
      )}
      {(uslugaParam.datatype === UslugaParamType.Number ||
        uslugaParam.datatype === UslugaParamType.Sum ||
        uslugaParam.datatype === UslugaParamType.Weight) && (
        <TextField
          label={
            uslugaParam.name +
            (uslugaParam.unit?.[0] ? `, ${uslugaParam.unit?.[0]}` : "")
          }
          variant="standard"
          size="small"
          value={value}
          onChange={onChangeNumber(uslugaParam.param)}
        />
      )}
      {uslugaParam.datatype === UslugaParamType.Array && (
        <Autocomplete
          value={value}
          options={uslugaParam.list ?? []}
          sx={{ marginTop: "6px" }}
          size="small"
          onChange={onChangeAC(uslugaParam.param)}
          getOptionLabel={getOptionLabel}
          renderInput={renderInput(uslugaParam.name)}
        />
      )}
      {uslugaParam.datatype === UslugaParamType.Boolean && (
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(value)}
              onChange={onChangeBoolean(uslugaParam.param)}
            />
          }
          label={<Typography fontSize={14}>{uslugaParam.name}</Typography>}
        />
      )}
      {uslugaParam.datatype === UslugaParamType.Date && (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
          <DatePicker
            label={uslugaParam.name}
            value={dayjs(value)}
            onChange={onChangeDate(uslugaParam.param)}
            sx={{ "& .MuiInputBase-root": { maxHeight: "40px" } }}
          />
        </LocalizationProvider>
      )}
    </>
  );
});

export default UslugaParam;
