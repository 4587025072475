import { memo, useCallback } from "react";
import { IUslugaService } from "../../../../../Store/Usluga/interfaces";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { uslugaActions } from "../../../../../Store/Usluga";
import uslugaSelectors from "../../../../../Store/Usluga/selectors";
import Typography from "@mui/material/Typography";

const UslugaService = memo(
  ({ uslugaService }: { uslugaService: IUslugaService }) => {
    const dispatch = useDispatch();

    const storeValue: boolean =
      useSelector(uslugaSelectors.calcOptions).service.includes(
        uslugaService.id
      ) ?? false;

    const onChange = useCallback(
      (serviceId: number) => (event: any) => {
        const newValue = Boolean(event.target.checked);

        dispatch(
          uslugaActions.updateCalcOptionsServices({
            service: { id: serviceId, isEnabled: newValue },
            soff: uslugaService.serviceoff ?? [],
          })
        );
      },
      [dispatch, uslugaService.serviceoff]
    );

    return (
      <FormControlLabel
        control={
          <Switch checked={storeValue} onChange={onChange(uslugaService.id)} />
        }
        label={<Typography fontSize={14}>{uslugaService.name}</Typography>}
      />
    );
  }
);

export default UslugaService;
