import superagent from "superagent";
import { BACKEND_URL } from "../constants";
import { IProduct } from "../../Store/App/interfaces";
import { IBuyResponse } from "./interface";
import { isNil } from "lodash";

const getProductsList = async () =>
  superagent
    .get(`${BACKEND_URL}/product`)
    .then<IProduct[]>((response) => response.body);

const buy = async (productId: number, accessToken: string) => {
  const req = superagent.post(`${BACKEND_URL}/order`).send({ productId });

  if (!isNil(accessToken)) req.set("authorization", `Bearer ${accessToken}`);

  return req
    .then<IBuyResponse>((response) => response.body)
    .catch((error) => {
      console.log("Failed to buy product", error);
      throw error;
    });
};

const paymentApi = { getProductsList, buy };

export default paymentApi;
