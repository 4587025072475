import { createSelector } from "@reduxjs/toolkit";
import { AppState, LoadingState } from "../interfaces";
import uslugaSelectors from "../Usluga/selectors";

const loadingState = (state: AppState): boolean =>
  state.app.loadingState === LoadingState.loading;

const returnIndexDefault = (state: AppState) => state.app.returnIndexDefault;
const innDogovorDefault = (state: AppState) => state.app.innDogovorDefault;

const isLoading = createSelector(
  uslugaSelectors.isLoading,
  loadingState,
  (isUslugaLoading, isAppLoading) => isUslugaLoading || isAppLoading
);

const isSettingsFormOpen = (state: AppState) => state.app.modals.settings;
const isPaymentFormOpen = (state: AppState) => state.app.modals.payment;
const isPaymentCompleteFormOpen = (state: AppState) =>
  state.app.modals.paymentComplete;
const isCancelSubscriptionFormOpen = (state: AppState) =>
  state.app.modals.setAutoProlongSubscription;
const isAboutAuthorModalOpen = (state: AppState) =>
  state.app.modals.aboutAuthor;
const isAboutAppModalOpen = (state: AppState) => state.app.modals.aboutApp;

const credentialsFormState = (state: AppState) => state.app.modals.credentials;

const paymentCompleteState = (state: AppState) =>
  state.app.paymentForm.paymentCompleteState;

const products = (state: AppState) => state.app.paymentForm.products;
const productsLoadingState = (state: AppState) =>
  state.app.paymentForm.productsLoadingState;
const buyingLoadingState = (state: AppState) =>
  state.app.paymentForm.buyingLoadingState;
const selectedProduct = (state: AppState) =>
  state.app.paymentForm.selectedProduct;

const appSelectors = {
  loadingState,
  returnIndexDefault,
  innDogovorDefault,
  isLoading,
  isSettingsFormOpen,
  credentialsFormState,
  isPaymentFormOpen,
  isPaymentCompleteFormOpen,
  isCancelSubscriptionFormOpen,
  isAboutAuthorModalOpen,
  isAboutAppModalOpen,
  paymentCompleteState,
  products,
  productsLoadingState,
  buyingLoadingState,
  selectedProduct,
};

export default appSelectors;
