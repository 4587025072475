/* eslint-disable jsx-a11y/anchor-has-content */
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { memo, useCallback } from "react";
import { SettingsContainer } from "../Settings/styled";
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../../Store/App/selectors";
import { appActions } from "../../../Store/App";
import { Button, Typography } from "@mui/material";

const AboutApp = memo(() => {
  const dispatch = useDispatch();

  const isOpen = useSelector(appSelectors.isAboutAppModalOpen);

  const onClick = useCallback(() => {
    dispatch(appActions.openAboutAppModal());
  }, [dispatch]);

  const onClose = useCallback(() => {
    dispatch(appActions.closeAboutAppModal());
  }, [dispatch]);

  return (
    <>
      <Box>
        <Button onClick={onClick}>О сайте</Button>{" "}
      </Box>
      <Modal open={isOpen} closeAfterTransition onClose={onClose}>
        <Fade in={isOpen}>
          <SettingsContainer style={{ justifyContent: "center", width: "80%" }}>
            <Box sx={{ margin: "8px" }}>
              <Typography fontWeight={500} fontSize={32} variant="h4">
                О сайте
              </Typography>
            </Box>
            <Box sx={{ minHeight: "50px" }}>
              <Typography variant="h6" fontSize={18}>
                На этом сайте вы сможете рассчитать стоимость и сроки пересылки
                отправлений Почты России с учетом всех возможных параметров
                отправлений и дополнительных услуг.
                <br /> <br /> Вверху слева выберите тип отправления.
                <br /> <br /> Все отправления разбиты на категории:
              </Typography>
              <Typography variant="h6" fontSize={18}>
                - посылки для физических лиц (доступно бесплатно)
                <br />
                - посылки для юридических лиц (доступно по подписке)
                <br />
                - EMS (доступно по подписке)
                <br />
                - международные (доступно по подписке)
                <br />
                - доставка грузов (доступно по подписке)
                <br />
              </Typography>
              <br /> <br />
              <Typography variant="h6" fontSize={18}>
                Если нужно, введите параметры отправления и выберите
                дополнительные услуги. Некоторые услуги не могут быть выбранны
                одновременно, поэтому будут автоматически отключаться.
                <br />
                <br />
                Для выбора мест "откуда" и "куда" введите адрес или индекс, или
                кликните по карте. Для введенного адреса автоматически будет
                найдено подходящее отделение Почты России рядом, а при клике по
                карте - 3 ближайших к этому месту.
                <br />
                <br />
                Когда все параметры выбраны, нажмите "рассчитать". Вы увидите
                полную детализацию цены, если нажмете на иконку с (?) рядом с
                ценой отправки.
              </Typography>
            </Box>
          </SettingsContainer>
        </Fade>
      </Modal>
    </>
  );
});

export default AboutApp;
