import {
  Box,
  ClickAwayListener,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, ChangeEvent, memo, useMemo, useState } from "react";
import { appActions } from "../../../../../Store/App";
import appSelectors from "../../../../../Store/App/selectors";

const ReturnIndexDefault = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const label = useMemo(() => "Индекс места вруч. возврата по-умолчанию", []);

  const returnIndexDefault = useSelector(appSelectors.returnIndexDefault);

  const onChangeIndexDefault = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue =
        event.target.value === "" ? null : Number(event.target.value);

      if (isNaN(Number(event.target.value))) return;

      dispatch(appActions.updateReturnIndexDefault(newValue));
    },
    [dispatch]
  );

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  }, []);

  const onClickAway = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <FormControl sx={{ marginTop: "12px" }} fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        value={returnIndexDefault ?? ""}
        onChange={onChangeIndexDefault}
        endAdornment={
          <InputAdornment position="end">
            <ClickAwayListener onClickAway={onClickAway}>
              <Box>
                <Popper
                  open={open}
                  anchorEl={anchorEl}
                  placement={"bottom-end"}
                  transition
                  sx={{ maxWidth: "500px", zIndex: 9999 }}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="subtitle1">
                            Используется Почтой России при расчетах возвратного
                            тарифа.
                            <br />
                            <br />
                            Если указан в параметрах отправления, то некоторые
                            выбранные доп. услуги могут игнорироваться Почтой в
                            расчетах. Рекомендую пользоваться с осторожностью.
                          </Typography>
                        </Box>
                      </Paper>
                    </Fade>
                  )}
                </Popper>
                <IconButton edge="end" onClick={onClick}>
                  <HelpOutlineIcon />
                </IconButton>
              </Box>
            </ClickAwayListener>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  );
};

export default memo(ReturnIndexDefault);
