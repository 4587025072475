import { createSelector } from "@reduxjs/toolkit";
import { AppState, LoadingState } from "../interfaces";

const getSelectedUsluga = (state: AppState) => state.usluga.selectedUsluga;

const getUslugasList = (state: AppState) => state.usluga.uslugas;
const getCategoriesList = (state: AppState) => state.usluga.categories;

const getUslugaParams = (state: AppState) =>
  [...state.usluga.selectedUslugaParams].filter(
    (p) => !["from", "to", "service"].includes(p.id)
  );
const getUslugaServices = (state: AppState) =>
  state.usluga.selectedUslugaServices;

const isLoading = (state: AppState) =>
  state.usluga.loadingState === LoadingState.loading;

const calcOptions = (state: AppState) => state.usluga.calcOptions;

const getUslugaListSorted = createSelector(getUslugasList, (uslugas) => {
  return uslugas;
});

const opsToLoadingState = (state: AppState) => state.usluga.opsLoading.toState;
const opsFromLoadingState = (state: AppState) =>
  state.usluga.opsLoading.fromState;

const getIndexFrom = (state: AppState) => state.usluga.calcOptions.from;
const getIndexTo = (state: AppState) => state.usluga.calcOptions.to;

const getSuggestionFrom = (state: AppState) => state.usluga.fromSuggestion;
const getSuggestionTo = (state: AppState) => state.usluga.toSuggestion;

const getSuggestion = (variant: "from" | "to") => (state: AppState) => {
  if (variant === "from") return getSuggestionFrom(state);
  if (variant === "to") return getSuggestionTo(state);

  throw new Error(`getSuggestion unknown variant: ${variant}`);
};

const calcResults = (state: AppState) => state.usluga.calcResults;

const uslugaSelectors = {
  isLoading,
  getSelectedUsluga,
  getUslugasList: getUslugaListSorted,
  getCategoriesList,
  getUslugaParams,
  getUslugaServices,

  getIndexFrom,
  getIndexTo,
  opsToLoadingState,
  opsFromLoadingState,
  getSuggestion,
  calcOptions,
  calcResults,
};

export default uslugaSelectors;
