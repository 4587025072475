/* eslint-disable jsx-a11y/anchor-has-content */
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { memo, useCallback } from "react";
import { SettingsContainer } from "../Settings/styled";
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../../Store/App/selectors";
import { appActions } from "../../../Store/App";
import { Button, Typography } from "@mui/material";

const AboutAuthor = memo(() => {
  const dispatch = useDispatch();

  const isOpen = useSelector(appSelectors.isAboutAuthorModalOpen);

  const onClick = useCallback(() => {
    dispatch(appActions.openAboutAuthorModal());
  }, [dispatch]);

  const onClose = useCallback(() => {
    dispatch(appActions.closeAboutAuthorModal());
  }, [dispatch]);

  return (
    <>
      <Box>
        <Button onClick={onClick}>Об авторе</Button>{" "}
      </Box>
      <Modal open={isOpen} closeAfterTransition onClose={onClose}>
        <Fade in={isOpen}>
          <SettingsContainer style={{ justifyContent: "center" }}>
            <Box sx={{ minHeight: "50px", marginBottom: "30px" }}>
              <Typography variant="h6">Связь с автором</Typography>
            </Box>
            <Box sx={{ minHeight: "50px" }}>
              <Typography variant="h6">
                E-mail:{" "}
                <a href="mailto:i.am@igor-ushakov.ru">i.am@igor-ushakov.ru</a>
              </Typography>
            </Box>
            <Box sx={{ minHeight: "50px" }}>
              <Typography variant="h6">
                Telegram: <a href="https://t.me/hino_2">@hino_2</a>
              </Typography>
            </Box>
          </SettingsContainer>
        </Fade>
      </Modal>
    </>
  );
});

export default AboutAuthor;
