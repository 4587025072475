import superagent from "superagent";
import { BACKEND_URL } from "../constants";
import {
  ICalcTariffRequest,
  ICalcTariffResult,
  IUsluga,
} from "../../Store/Usluga/interfaces";
import { isNil, isObject, isString } from "lodash";

const getList = async () =>
  superagent
    .get(`${BACKEND_URL}/russian-post/uslugas`)
    .then<IUsluga[]>((response) => response.body);

const getById = async (id: number) =>
  superagent
    .get(`${BACKEND_URL}/russian-post/uslugas/${id}`)
    .then<IUsluga>((response) => response.body);

const calculate = async (
  params: ICalcTariffRequest,
  accessToken: string | null
) => {
  const req = superagent
    .post(`${BACKEND_URL}/russian-post/uslugas/calc`)
    .send(params);

  if (!isNil(accessToken)) req.set("authorization", `Bearer ${accessToken}`);

  return req
    .then<ICalcTariffResult>((response) => response.body)
    .catch<ICalcTariffResult>((response) => {
      let errors = JSON.parse(response.response.text)?.message ?? [];

      if (isObject(errors[0]))
        errors = errors.map((e: { msg: string }) => e.msg);

      if (isString(errors)) errors = [errors];

      return {
        price: null,
        nds: null,
        details: null,
        delivery: null,
        errors,
      };
    });
};

const rpUslugaApi = {
  getList,
  getById,
  calculate,
};

export default rpUslugaApi;
