import styled from "@emotion/styled";

export const UslugaOptionsContainer = styled("div")(
  () => `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 3px;
  position: relative;
  padding: 3px;
  box-sizing: border-box;
  background: #FFF;
  background-clip: padding-box;
  border: solid 2px transparent;
  border-radius: 3px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    /* !importanté */
    border-radius: inherit;
    /* !importanté */
    background: linear-gradient(47deg, #00c9a7, #845ec2);
`
);
