import { AppState } from "../interfaces";

const lastClickCoords = (state: AppState) => state.map.lastClickCoords;
const center = (state: AppState) => state.map.center;
const zoom = (state: AppState) => state.map.zoom;
const fromOps = (state: AppState) => state.map.fromOPS;
const toOps = (state: AppState) => state.map.toOPS;
const selectedOps = (state: AppState) => state.map.selectedOps;

const mapSelectors = {
  zoom,
  center,
  lastClickCoords,
  fromOps,
  toOps,
  selectedOps,
};

export default mapSelectors;
