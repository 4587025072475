import { all, fork } from "redux-saga/effects";
import uslugasSaga from "./Usluga/sagas";
import appSaga from "./App/saga";
import authSaga from "./Auth/saga";
import mapSaga from "./Map/saga";

function* rootSaga(): Generator {
  yield all([fork(uslugasSaga), fork(appSaga), fork(authSaga), fork(mapSaga)]);
}

export default rootSaga;
