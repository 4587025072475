import { LoadingState } from "../interfaces";
import { IMapState, IOps } from "./interface";

const setLastClickCoords = (
  state: IMapState,
  action: { payload: [number, number] }
) => {
  state.lastClickCoords = action.payload;
};

const getOPSByCoords = (state: IMapState) => {
  state.loadingState = LoadingState.loading;
};

const getOPSByCoordsSuccess = (state: IMapState) => {
  state.loadingState = LoadingState.success;
};

const getOPSByCoordsFail = (state: IMapState) => {
  state.loadingState = LoadingState.fail;
};

const addSelectedOps = (state: IMapState, action: { payload: IOps[] }) => {
  const notAlreadySelectedOps = action.payload.filter(
    (ops) =>
      !state.selectedOps.find((sops) => sops.postalCode === ops.postalCode)
  );

  state.selectedOps = [...state.selectedOps, ...notAlreadySelectedOps];
};

const clearSelectedOps = (state: IMapState) => {
  state.selectedOps = [];
};

const setMapCenter = (
  state: IMapState,
  action: { payload: [number, number] }
) => {
  state.center = action.payload;
};

const setMapZoom = (state: IMapState, action: { payload: number }) => {
  state.zoom = action.payload;
};

const placeOpsOnMapAndOpenBalloon = (
  state: IMapState,
  action: { payload: "to" | "from" }
) => {};

const mapReducers = {
  setLastClickCoords,
  getOPSByCoords,
  getOPSByCoordsSuccess,
  getOPSByCoordsFail,
  addSelectedOps,
  clearSelectedOps,
  setMapCenter,
  setMapZoom,
  placeOpsOnMapAndOpenBalloon,
};

export default mapReducers;
