import { LoadingState } from "../interfaces";
import { CredentialsModalState, IAppState, IProduct } from "./interfaces";

const init = (state: IAppState) => {
  state.loadingState = LoadingState.loading;
};

const initSuccess = (state: IAppState) => {
  state.loadingState = LoadingState.success;
};

const updateReturnIndexDefault = (
  state: IAppState,
  action: { payload: number | null }
) => {
  state.returnIndexDefault = action.payload;
};

const updateInnDogovorDefault = (
  state: IAppState,
  action: { payload: string | null }
) => {
  state.innDogovorDefault = action.payload;
};

const openSettingsForm = (state: IAppState) => {
  state.modals.settings = true;
};

const closeSettingsForm = (state: IAppState) => {
  state.modals.settings = false;
};

const setCredentialsFormState = (
  state: IAppState,
  action: { payload: CredentialsModalState }
) => {
  state.modals.credentials = action.payload;
};

const openPaymentCompleteForm = (state: IAppState) => {
  state.modals.paymentComplete = true;
};

const closePaymentCompleteForm = (state: IAppState) => {
  state.modals.paymentComplete = false;
};

const setPaymentCompleteState = (
  state: IAppState,
  action: { payload: boolean }
) => {
  state.paymentForm.paymentCompleteState = action.payload;
};

const openPaymentForm = (state: IAppState) => {
  state.modals.payment = true;
};

const closePaymentForm = (state: IAppState) => {
  state.modals.payment = false;
};

const openSetAutoProlongSubscriptionForm = (state: IAppState) => {
  state.modals.setAutoProlongSubscription = true;
};

const closeSetAutoProlongSubscriptionForm = (state: IAppState) => {
  state.modals.setAutoProlongSubscription = false;
};

const openAboutAppModal = (state: IAppState) => {
  state.modals.aboutApp = true;
};

const closeAboutAppModal = (state: IAppState) => {
  state.modals.aboutApp = false;
};

const openAboutAuthorModal = (state: IAppState) => {
  state.modals.aboutAuthor = true;
};

const closeAboutAuthorModal = (state: IAppState) => {
  state.modals.aboutAuthor = false;
};

const setAutoProlongSubscription = (state: IAppState) => {
  state.loadingState = LoadingState.loading;
};

const setAutoProlongSubscriptionSuccess = (state: IAppState) => {
  state.loadingState = LoadingState.success;
};
const setAutoProlongSubscriptionFail = (state: IAppState) => {
  state.loadingState = LoadingState.fail;
};

const getProductsList = (state: IAppState) => {
  state.paymentForm.productsLoadingState = LoadingState.loading;
};

const getProductsListSuccess = (
  state: IAppState,
  action: { payload: IProduct[] }
) => {
  state.paymentForm.products = action.payload;

  state.paymentForm.productsLoadingState = LoadingState.success;
};

const getProductsListFail = (state: IAppState) => {
  state.paymentForm.productsLoadingState = LoadingState.fail;
};

const setSelectedProduct = (
  state: IAppState,
  action: { payload: IProduct }
) => {
  state.paymentForm.selectedProduct = action.payload;
};

const buy = (state: IAppState) => {
  state.paymentForm.buyingLoadingState = LoadingState.loading;
};

const buySuccess = (state: IAppState, action: { payload: string }) => {
  state.paymentForm.psPaymentUrl = action.payload;

  state.paymentForm.buyingLoadingState = LoadingState.success;
};

const buyFail = (state: IAppState) => {
  state.paymentForm.buyingLoadingState = LoadingState.fail;
};

export const appReducers = {
  init,
  initSuccess,
  updateReturnIndexDefault,
  updateInnDogovorDefault,
  openSettingsForm,
  closeSettingsForm,
  setCredentialsFormState,
  openPaymentForm,
  closePaymentForm,
  openPaymentCompleteForm,
  closePaymentCompleteForm,
  setPaymentCompleteState,
  openSetAutoProlongSubscriptionForm,
  closeSetAutoProlongSubscriptionForm,
  openAboutAppModal,
  closeAboutAppModal,
  openAboutAuthorModal,
  closeAboutAuthorModal,
  setAutoProlongSubscription,
  setAutoProlongSubscriptionSuccess,
  setAutoProlongSubscriptionFail,
  getProductsList,
  getProductsListSuccess,
  getProductsListFail,
  setSelectedProduct,
  buy,
  buySuccess,
  buyFail,
};
