import {
  all,
  call,
  cancel,
  delay,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";
import { mapActions } from ".";
import opsApi from "../../API/OPS";
import { ThenArg } from "../interfaces";
import mapSelectors from "./selectors";
import uslugaSelectors from "../Usluga/selectors";
import { isNil, isNumber } from "lodash";
import { IOps } from "./interface";
import { convertOpsToYMapsObject } from "../../Components/Map/helpers";
import { uslugaActions } from "../Usluga";

function* setLastClickCoords() {
  try {
    yield put(mapActions.getOPSByCoords());

    const coords: ReturnType<typeof mapSelectors.lastClickCoords> =
      yield select(mapSelectors.lastClickCoords);
    const opss: ThenArg<typeof opsApi.getByCoords> = yield call(
      opsApi.getByCoords,
      coords[0],
      coords[1]
    );

    yield put(mapActions.addSelectedOps(opss));
  } catch (error) {
    console.log("setLastClickCoords Fail", error);
    yield put(mapActions.getOPSByCoordsFail());
  }
}

function* placeOpsOnMapAndOpenBalloon(action: { payload: "to" | "from" }) {
  try {
    const opsIndexToAdd: number =
      action.payload === "to"
        ? yield select(uslugaSelectors.getIndexTo)
        : yield select(uslugaSelectors.getIndexFrom);

    // opsIndexToAdd could be null
    if (!isNumber(opsIndexToAdd)) yield cancel();

    // @ts-ignore window
    const yMapObjManager = window.objManager;
    if (isNil(yMapObjManager)) yield cancel();

    if (yMapObjManager.getObjectState(opsIndexToAdd).found) {
      yMapObjManager.objects.balloon.open(opsIndexToAdd);
      yield cancel();
    }

    /** OPS with index 'opsIndexToAdd' is not on the map */
    const selectedOps: ReturnType<typeof mapSelectors.selectedOps> =
      yield select(mapSelectors.selectedOps);

    yield put(
      action.payload === "to"
        ? uslugaActions.loadOpsTo()
        : uslugaActions.loadOpsFrom()
    );

    const opsToAdd: IOps = selectedOps.find(
      (sops) => sops.postalCode === opsIndexToAdd
    )
      ? selectedOps.find((sops) => sops.postalCode === opsIndexToAdd)
      : yield call(opsApi.getByIndex, opsIndexToAdd);

    if (isNil(opsToAdd)) throw new Error("Failed to add ops to map");

    yield put(mapActions.addSelectedOps([opsToAdd]));

    yMapObjManager.add(convertOpsToYMapsObject(opsToAdd));
    yield delay(100);

    yMapObjManager.objects.balloon.open(opsToAdd.postalCode);

    yield put(
      action.payload === "to"
        ? uslugaActions.loadOpsToSuccess()
        : uslugaActions.loadOpsFromSuccess()
    );
  } catch (error) {
    console.log(`placeOpsOnMapAndOpenBalloon failed`, error);
    yield put(
      action.payload === "to"
        ? uslugaActions.loadOpsToFail()
        : uslugaActions.loadOpsFromFail()
    );
  }
}

function* mapSaga() {
  yield all([
    takeLatest(mapActions.setLastClickCoords, setLastClickCoords),
    takeLatest(
      mapActions.placeOpsOnMapAndOpenBalloon,
      placeOpsOnMapAndOpenBalloon
    ),
  ]);
}

export default mapSaga;
