import styled from "@emotion/styled";

export const CalculateResultsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  maxHeight: "80px",
  marginTop: "20px",
});

export const CalculateResultsPriceContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  maxHeight: "40px",
});

export const CalculateResultsDeliveryContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
  maxHeight: "40px",
});

export const CalculateResultsErrorsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
});
