import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { isNil } from "lodash";
import { IUser } from "../../../../Store/Auth/interfaces";
import { memo, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { authActions } from "../../../../Store/Auth";

const UserForm = ({ user }: { user: IUser }) => {
  const dispatch = useDispatch();

  const subscriptionMessage = useMemo(() => {
    const now = dayjs(Date.now()).endOf("day").valueOf();
    const subscribedUntil = dayjs(user.subscribedUntil).valueOf();

    if (isNil(user.subscribedUntil)) return "";
    if (subscribedUntil > now)
      return `Подписка до ${dayjs(user.subscribedUntil).format("DD-MM-YYYY")}`;
    if (subscribedUntil <= now) return `Подписка не оформлена`;

    return "";
  }, [user.subscribedUntil]);

  const emailValidatedAtMsg = useMemo(
    () =>
      `E-mail подтвержден ${dayjs(user.emailValidatedAt).format(
        "DD-MM-YYYY HH:mm"
      )}`,
    [user.emailValidatedAt]
  );

  const emailNotValidatedMsg = useMemo(
    () =>
      `E-mail не подтвержден. Нажмите на эту иконку и получите письмо на почту.`,
    []
  );

  const onClickValidateEmail = useCallback(() => {
    dispatch(authActions.validateEmail());
  }, [dispatch]);

  if (isNil(user.accessToken)) return <></>;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6" fontWeight={700}>
          {user.email}
        </Typography>
        {!isNil(user.emailValidatedAt) ? (
          <Tooltip title={emailValidatedAtMsg}>
            <IconButton>
              <DoneIcon color="success" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={emailNotValidatedMsg}>
            <IconButton onClick={onClickValidateEmail}>
              <CancelIcon color="error" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Typography variant="h6">{subscriptionMessage}</Typography>
    </>
  );
};

export default memo(UserForm);
