import styled from "@emotion/styled";

export const LeftPanelInnerContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  padding: "3px 3px 3px 3px",
}));

export const DirectionsContainer = styled("div")(() => ({
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
}));

export const AboutContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-evenly",
  marginTop: "10px",
}));
