import styled from "@emotion/styled";

export const MainContainer = styled("div")(() => ({
  display: "flex",
  padding: 0,
  width: "100vw",
  height: "100vh",
  margin: 0,
}));

export const MapContainer = styled("div")(() => ({
  padding: 0,
  width: "60vw",
  height: "100vh",
  margin: 0,
}));

export const LeftPanelContainer = styled("div")(() => ({
  display: "flex",
  padding: 0,
  width: "40vw",
  height: "100vh",
  margin: 0,
  overflowY: "auto",
  overflowX: "hidden",
}));

export const LeftPanelContainerMobile = styled("div")(() => ({
  display: "flex",
  padding: 0,
  width: "100vw",
  height: "100vh",
  margin: 0,
  overflowY: "auto",
  overflowX: "hidden",
}));
