import { createSlice } from "@reduxjs/toolkit";
import { DeliveryCompany, IUslugaSelectorState } from "./interfaces";
import uslugaReducers from "./reducers";
import { LoadingState } from "../interfaces";
import dayjs from "dayjs";

export const uslugaSelectorInitialState: IUslugaSelectorState = {
  selectedCompany: DeliveryCompany.RP,
  selectedUsluga: null,
  selectedUslugaParams: [],
  selectedUslugaServices: [],
  uslugas: [],
  categories: [],
  loadingState: LoadingState.loading,
  fromSuggestion: {
    data: { postal_code: "" },
    value: "",
    unrestricted_value: "",
  },
  toSuggestion: {
    data: { postal_code: "" },
    value: "",
    unrestricted_value: "",
  },
  opsLoading: {
    fromState: LoadingState.idle,
    toState: LoadingState.idle,
  },
  calcOptions: {
    from: null,
    to: null,
    object: null,
    weight: 1000,
    date: dayjs().format("YYYYMMDD"),
    time: dayjs().format("HHmm"),
    service: [],
  },
  calcResults: {
    price: null,
    nds: null,
    delivery: null,
    details: null,
    errors: null,
  },
};

export const uslugasSlice = createSlice({
  name: "uslugaSelector",
  initialState: uslugaSelectorInitialState,
  reducers: uslugaReducers,
});

// Action creators are generated for each case reducer function
export const uslugaActions = uslugasSlice.actions;

export default uslugasSlice.reducer;
