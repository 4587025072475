import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import { memo, useCallback } from "react";

const RecurrentPaymentsTerms = memo(
  ({
    checked1,
    checked2,
    setChecked1,
    setChecked2,
  }: {
    checked1: boolean;
    checked2: boolean;
    setChecked1: React.Dispatch<React.SetStateAction<boolean>>;
    setChecked2: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const onChange1 = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked1(event.target.checked);
      },
      [setChecked1]
    );

    const onChange2 = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked2(event.target.checked);
      },
      [setChecked2]
    );

    return (
      <Box mt={2}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={checked1} onChange={onChange1} />}
            label={
              <Typography variant="subtitle2">
                Я соглашаюсь с{" "}
                <a href="recurrent.docx" download="recurrent.docx">
                  Офертой регулярных платежей
                </a>
              </Typography>
            }
          />
          <FormControlLabel
            control={<Checkbox checked={checked2} onChange={onChange2} />}
            label={
              <Typography variant="subtitle2">
                Я соглашаюсь с{" "}
                <a href="agreement.docx" download="agreement.docx">
                  Соглашением с подпиской
                </a>
              </Typography>
            }
          />
        </FormGroup>
      </Box>
    );
  }
);

export default RecurrentPaymentsTerms;
