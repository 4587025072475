import { memo, useCallback, useMemo } from "react";
import { AddressSuggestions, DaDataAddressSuggestion } from "react-dadata";
import { useDispatch, useSelector } from "react-redux";
import env from "react-dotenv";
import "react-dadata/dist/react-dadata.css";
import uslugaSelectors from "../../../Store/Usluga/selectors";
import { uslugaActions } from "../../../Store/Usluga";
import { DirectionElementContainer, IndexButton } from "./styled";
import { isNil } from "lodash";
import { mapActions } from "../../../Store/Map";
import { LoadingState } from "../../../Store/interfaces";

const DirectionInput = memo(({ variant }: { variant: "from" | "to" }) => {
  const dispatch = useDispatch();

  const value = useSelector(uslugaSelectors.getSuggestion(variant));
  const index = useSelector(variantSelectorMap[variant]);
  const opsLoadingState = useSelector(opsLoadingStateSelectorsMap[variant]);

  const label = useMemo(() => labelMap[variant], [variant]);

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value.match(/^\d{6}$/)) return;

      dispatch(
        uslugaActions.updateCalcOptions({
          [variant]: Number(event.target.value),
        })
      );
      dispatch(mapActions.placeOpsOnMapAndOpenBalloon(variant));
    },
    [dispatch, variant]
  );

  const onChange = useCallback(
    (v: DaDataAddressSuggestion | undefined) => {
      if (isNil(v)) return;

      if (variant === "from") dispatch(uslugaActions.setSuggestionFrom(v));
      if (variant === "to") dispatch(uslugaActions.setSuggestionTo(v));

      if (isNil(v.data.postal_code)) return;

      dispatch(
        uslugaActions.updateCalcOptions({
          [variant]: Number(v.data.postal_code),
        })
      );
      dispatch(mapActions.placeOpsOnMapAndOpenBalloon(variant));
    },
    [dispatch, variant]
  );

  const onClickButton = useCallback(() => {
    dispatch(mapActions.placeOpsOnMapAndOpenBalloon(variant));
  }, [dispatch, variant]);

  return (
    <DirectionElementContainer>
      <AddressSuggestions
        token={String(env.DADATA_APIKEY)}
        delay={100}
        count={10}
        // @ts-ignore
        value={value}
        inputProps={{
          placeholder: `Введите адрес/индекс "${label}"`,
          onChange: onInputChange,
        }}
        onChange={onChange}
      />
      <IndexButton
        variant="text"
        size="large"
        loading={opsLoadingState === LoadingState.loading}
        color={opsLoadingState === LoadingState.fail ? "error" : "primary"}
        onClick={onClickButton}
      >
        {index ?? "Не выбрано"}
      </IndexButton>
    </DirectionElementContainer>
  );
});

export default DirectionInput;

const labelMap = {
  from: "откуда",
  to: "куда",
};

const variantSelectorMap = {
  from: uslugaSelectors.getIndexFrom,
  to: uslugaSelectors.getIndexTo,
};

const opsLoadingStateSelectorsMap = {
  from: uslugaSelectors.opsFromLoadingState,
  to: uslugaSelectors.opsToLoadingState,
};
