import React, { useCallback, useEffect, useState } from "react";
import {
  MainContainer,
  LeftPanelContainer,
  MapContainer,
  LeftPanelContainerMobile,
} from "../Components/MainContainers";
import { LeftPanel } from "../Components/LeftPanel";
import { useDispatch } from "react-redux";
import { appActions } from "../Store/App";
import PaymentForm from "../Components/PaymentForm";
import Map from "../Components/Map";
import PaymentCompleteForm from "../Components/PaymentForm/PaymentComplete";

function App() {
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(
    /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth < 760
  );

  const handleWidthChange = useCallback(() => {
    setIsMobile(window.innerWidth < 760 ? true : false);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWidthChange);
    return () => window.removeEventListener("resize", handleWidthChange);
  }, [handleWidthChange, isMobile]);

  useEffect(() => {
    dispatch(appActions.init());
  }, [dispatch]);

  return (
    <MainContainer>
      {isMobile ? (
        <LeftPanelContainerMobile>
          <LeftPanel />
        </LeftPanelContainerMobile>
      ) : (
        <>
          <LeftPanelContainer>
            <LeftPanel />
          </LeftPanelContainer>
          <MapContainer>
            <Map />
          </MapContainer>
        </>
      )}
      <PaymentForm />
      <PaymentCompleteForm />
    </MainContainer>
  );
}

export default App;
