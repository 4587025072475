import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../../Store/App/selectors";
import { memo, useCallback } from "react";
import { appActions } from "../../../Store/App";
import Typography from "@mui/material/Typography";
import authSelectors from "../../../Store/Auth/selectors";
import { BigLoadingButton, ModalContainer } from "../styled";
import { BigButton } from "../../LeftPanel/Settings/styled";

const SetAutoProlongSubscription = memo(() => {
  const dispatch = useDispatch();

  const open = useSelector(appSelectors.isCancelSubscriptionFormOpen);
  const loadingState = useSelector(appSelectors.loadingState);
  const isAutoProlongSubscription = useSelector(
    authSelectors.isAutoProlongSubscription
  );

  const onClose = useCallback(() => {
    dispatch(appActions.closeSetAutoProlongSubscriptionForm());
  }, [dispatch]);

  const onClickConfirm = useCallback(() => {
    dispatch(appActions.setAutoProlongSubscription());
    dispatch(appActions.closeSetAutoProlongSubscriptionForm());
  }, [dispatch]);

  return (
    <Modal open={open} closeAfterTransition onClose={onClose}>
      <Fade in={open}>
        <ModalContainer>
          <Typography variant="h6">
            {isAutoProlongSubscription
              ? "Отменить подписку?"
              : "Возобновить подписку?"}
            <br />
            Ваша подписка останется активна до конца оплаченного периода, а
            потом будет
            {!isAutoProlongSubscription
              ? " оплачена автоматически"
              : " остановлена"}
            .
          </Typography>
          <BigButton variant="contained" onClick={onClose}>
            ЗАКРЫТЬ
          </BigButton>
          <BigLoadingButton
            loading={loadingState}
            variant="contained"
            color={isAutoProlongSubscription ? "error" : "success"}
            onClick={onClickConfirm}
          >
            {isAutoProlongSubscription ? "ОТМЕНИТЬ " : "АКТИВИРОВАТЬ "}ПОДПИСКУ
          </BigLoadingButton>
        </ModalContainer>
      </Fade>
    </Modal>
  );
});

export default SetAutoProlongSubscription;
