import { memo } from "react";
import { useSelector } from "react-redux";
import uslugaSelectors from "../../../Store/Usluga/selectors";
import { UslugaOptionsContainer } from "./styled";
import UslugaParams from "./UslugaParams";
import UslugaServices from "./UslugaServices";

const UslugaOptions = memo(() => {
  const uslugaParams = useSelector(uslugaSelectors.getUslugaParams);
  const uslugaServices = useSelector(uslugaSelectors.getUslugaServices);

  return (
    <UslugaOptionsContainer>
      <UslugaParams uslugaParams={uslugaParams} />
      <UslugaServices uslugaServices={uslugaServices} />
    </UslugaOptionsContainer>
  );
});

export default UslugaOptions;
