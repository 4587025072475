import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { authActions } from "../../../../../Store/Auth";
import { BigButton } from "../../styled";

const LogoutForm = memo(() => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <BigButton variant="contained" color="error" onClick={onClick}>
        ВЫЙТИ
      </BigButton>
    </Box>
  );
});

export default LogoutForm;
