import { createSlice } from "@reduxjs/toolkit";
import { IMapState } from "./interface";
import mapReducers from "./reducers";
import { LoadingState } from "../interfaces";

export const mapInitialState: IMapState = {
  loadingState: LoadingState.idle,
  center: [56.78253179456204, 60.60676747325301], // TODO: remove
  zoom: 9,
  lastClickCoords: [0, 0],
  fromOPS: null,
  toOPS: null,
  selectedOps: [],
};

export const mapSlice = createSlice({
  name: "map",
  initialState: mapInitialState,
  reducers: mapReducers,
});

// Action creators are generated for each case reducer function
export const mapActions = mapSlice.actions;

export default mapSlice.reducer;
