import { configureStore } from "@reduxjs/toolkit";
import uslugaReducers from "./Usluga";
import appReducers from "./App";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./saga-root";
import authReducers from "./Auth";
import mapReducers from "./Map";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    app: appReducers,
    auth: authReducers,
    usluga: uslugaReducers,
    map: mapReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

// then run the saga
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const lsPrefix = "priceOfParcel";
